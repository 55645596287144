import React  from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { loadReCaptcha } from "react-recaptcha-v3";
import AuthRoutes from './routes/auth.routes';
import store from "./store";
import GlobalStyle from "./styles/global";
import Dev from "./components/Dev";
import './styles/routes.css';
import InternetConnection from "./components/InternetConnection";

const App: React.FC = () => {

  const siteKey = process.env.REACT_APP_RECAPTCHA as string;
  loadReCaptcha(siteKey);

  return(
    <Provider store={store}>
      <BrowserRouter>
        <Dev />
        <InternetConnection />
        <GlobalStyle />
        <AuthRoutes />
      </BrowserRouter>
    </Provider>
  )
};

export default App;
