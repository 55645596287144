import React from 'react';
import { Rotate, Rotate2 } from './styles';
import loadingIcon from '../../assets/icons/spinner.svg';

export const LoaderIcon: React.FC = () => {
  return (
    <Rotate2>
      <img src={loadingIcon} alt="Loader" />
    </Rotate2>
  );
};

export const Loader: React.FC = () => {
  return (
    <Rotate>
      <img src={loadingIcon} alt="Loader" />
      <h2>Carregando</h2>
    </Rotate>
  );
};
export const Loading = React.createElement(Loader);
