/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  faArrowRight,
  faCheck,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import Lottie from 'react-lottie'
// import { isAfter } from 'date-fns';
import { isAfter } from 'date-fns'
import downloadLottie from '../../assets/icons/arquivo-baixado.json'
import copyLottie from '../../assets/icons/copiar-codigo.json'
import iconInfoGreen from '../../assets/icons/information.svg'
import iconDownload from '../../assets/icons/download.svg'
// import iconBarCode from '../../assets/icons/barcode.svg';
import noInvoicesPendIcon from '../../assets/icons/noinvoices.svg'
import {
  Box,
  Left,
  Right,
  Container,
  Title,
  BoxAllInvoices,
  YearTitle,
  Invoice,
  BoxInvoiceRight,
  BoxInvoicesPending,
  Skeleton,
  SkeletonGreen,
  BoxPrice,
  BoxPriceMobile,
  BoxInfoDownload,
  BoxInfoCopy,
} from './styles'
import { newApi } from '../../services/api'
import loadingIcon from '../../assets/icons/loading.svg'
import loadingPrimaryIcon from '../../assets/icons/loading-primary.svg'
import {
  FaturaDTO,
  Invoice as InvoiceValue,
  StateInitial,
  User,
} from '../../utils/models'
import { PaymentSlip } from '../../components/PDF'
import { isAuthenticated } from '../../utils/auth'
import Footer from '../../components/Footer'

const DECIMAL_NUMBER = 10
const THREE_SECONDS = 3000

interface DataDashDTO {
  modules: StateInitial
  dispatch: any
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Home = ({ modules, dispatch }: DataDashDTO): any => {
  const location = useLocation<any>()
  const [invoices, setInvoices] = useState<Array<InvoiceValue>>()
  const [yearsInvoices, setYears] = useState<Array<number>>([])
  const [invoicesId, setInvoicesId] = useState<number[]>()
  const [loadingData, setLoadingData] = useState(true)
  const [donwloadIdInvoice, setIdInvoice] = useState<string>()
  const [showDetails, setShowDetails] = useState<string>()
  const history = useHistory()
  const [totalCart, setTotalCart] = useState(0)
  const [originalValue, setOriginalValue] = useState(0)
  const [penalty, setPenalty] = useState(0)
  const [fees, setFees] = useState(0)
  const [correction, setCorrection] = useState(0)
  const [loading, setLoading] = useState(false)
  const [showInfo, setShowInfo] = useState<number | null>()
  const [markAll, setMarkAll] = useState(false)
  const [downloadThis, setDownloadThis] = useState<InvoiceValue>()
  const [showInfoDownloadFile, setShowInfoDownloadFile] = useState<boolean>(
    false
  )

  function checkDueDate(dataInvoice: string): boolean {
    const [year, month, day] = dataInvoice.split('-')
    // console.log(dataInvoice)
    const dateInvoice = new Date(
      parseInt(year, DECIMAL_NUMBER),
      parseInt(month, DECIMAL_NUMBER) - 1,
      parseInt(day, DECIMAL_NUMBER)
    )
    dateInvoice.setHours(23,59,59)
    return isAfter(dateInvoice, new Date())
  }

  /* function showModalWhenDocEmpty(module: User, loadingSearchBd: boolean): unknown {
    return {
        type: 'SET_VISIBILITY_MODAL',
        module,
        loadingSearchBd,
    };
  } */

  function setLoadingSearchDb(
    module: boolean,
    showLoadingDb: boolean
  ): unknown {
    return {
      type: 'SET_SEARCH_DB_LOADING',
      module,
      showLoadingDb,
    }
  }

  useEffect(() => {
    if (invoices) {
      if (invoicesId) {
        if (invoicesId.length < invoices.length) {
          setMarkAll(false)
        } else {
          setMarkAll(true)
        }
      }
    }
  }, [invoices, invoicesId])

  /* useEffect(()=>{
    const first = localStorage.getItem('first_time');
    if(!modules.DATA_CLIENT.NUMERO_DOCTO_1 && first){
      setTimeout(() => {
        dispatch(showModalWhenDocEmpty(modules.DATA_CLIENT, true));
      }, 3000);
    }
  },[modules]); */

  useEffect(() => {
    if (isAuthenticated()) {
      const temp = localStorage.getItem('Invoices')
      if (temp) {
        const tempInvoices = JSON.parse(temp)
        if (tempInvoices[0] && tempInvoices[0].id) {


          setInvoices(tempInvoices)
          // console.log('12345')
          setLoadingData(false)
          searchDb()
        } else {
          setYears([])
          searchDb()
          setLoadingData(false)
        }
      } else {
        setYears([])
        searchDb()
        setLoadingData(true)
      }
    } else {
      history.push('/login')
    }
  }, [modules])

  useEffect(() => {
    if (invoices) {
      const data = []
      data.push(parseFloat(invoices[0].refDate.substr(3)))
      for (let i = 1; i < invoices.length; i += 1) {
        if (
          parseFloat(invoices[i].refDate.substr(3)) !==
          parseFloat(invoices[i - 1].refDate.substr(3))
        ) {
          data.push(parseFloat(invoices[i].refDate.substr(3)))
        }
      }
      setYears(data)
    }
  }, [invoices])

  useEffect(() => {
    if (location.state) {
      const tempId = location.state.data.map((fatura: FaturaDTO) => {
        return fatura.id
      })
      let cartTotalTemp = 0
      let originalTemp = 0
      let penaltyTemp = 0
      let feesTemp = 0
      let correctionTemp = 0
      location.state.data.forEach((fatura: FaturaDTO) => {
        penaltyTemp += fatura.multa
        feesTemp += fatura.juros
        correctionTemp += fatura.correcao
        cartTotalTemp += fatura.total
        originalTemp += fatura.fatura
      })
      setPenalty(penaltyTemp)
      setFees(feesTemp)
      setCorrection(correctionTemp)
      setOriginalValue(originalTemp)
      setTotalCart(cartTotalTemp)
      setInvoicesId(tempId)
    }
  }, [location, invoices])

  /* useEffect(()=>{
    const temp = localStorage.getItem('Invoices');
    if(temp){
      const tempInvoices = JSON.parse(temp);
      if(tempInvoices[0].id){
        setInvoices(tempInvoices);
        setLoadingData(false);
        searchDb();
      }else{
        setYears([]);
        searchDb();
      }
    }else{
      setYears([]);
      searchDb();
    }
   
  },[]) */

  async function searchDb(): Promise<void> {
    /* const token = sessionStorage.getItem('token')
    const dataClient = localStorage.getItem('info')
    setLoadingSearchDb(modules.SEARCH_DB, true)
    try {
      if (token && dataClient) {
        const { IDENT_UC } = JSON.parse(dataClient) as User
        const { data } = await api.get(`${IDENT_UC}/consumes`, {
          headers: {
            Authorization: `Basic ${token}`,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        })
        localStorage.setItem('invoices', JSON.stringify(data))
        setInvoices(data)
        localStorage.setItem('invoice', JSON.stringify(data[0]))
      }
      //  setLoadingData(false);
      setLoadingSearchDb(modules.SEARCH_DB, false)
    } catch (error) {
      history.push('/login')
    } */
  }

  function copyBarCode(inv: InvoiceValue): void {
    if (inv.invoice_id) setShowInfo(inv.invoice_id)
    const dummy = document.createElement('textarea')
    document.body.appendChild(dummy)
    dummy.value = inv.barcode || ''
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
    setTimeout(() => {
      setShowInfo(undefined)
    }, THREE_SECONDS)
  }

  function checkAllInvoices(checkbox: boolean): void {
    if (checkbox) {
      setTotalCart(0)
      setOriginalValue(0)
      let totalCartTemp = 0
      let originalValueTemp = 0
      setInvoicesId([])
      const invoiceTemp = [] as number[]
      if (invoices)
        invoices.forEach((invPend) => {
          totalCartTemp += invPend.totalPaymentDue as number
          originalValueTemp += invPend.totalPaymentDue as number
          if (invPend.invoice_id) {
            invoiceTemp.push(invPend.invoice_id)
          }
        })

      setTotalCart(totalCartTemp)
      setOriginalValue(originalValueTemp)

      setInvoicesId(invoiceTemp)
    } else {
      setTotalCart(0)
      setOriginalValue(0)
      setInvoicesId([])
    }

    /* if(checkbox){
      setPenalty(0);
      let penaltTemp = 0;
      setFees(0);
      let feesTemp = 0;
      setCorrection(0);
      let correctionTemp = 0;
      setOriginalValue(0);
      let originalValueTemp = 0;
      setTotalCart(0);
      let totalCartTemp = 0;
      setInvoicesId([])
      const invoicesIdTemp = [] as number[];
      const invoicesPending = invoices?.filter(inv => ["ABER", "REAV", "TRAN"].includes(inv.SIT_FATURA_EDT) && getSituation(inv) !== 6)
      if(invoicesPending){
        invoicesPending.forEach(invPend => {
          penaltTemp += parseFloat(invPend.C1_MULTA);
          feesTemp += parseFloat(invPend.C1_JUROS);
          correctionTemp += parseFloat(invPend.C1_CORRECAO);
          originalValueTemp += parseFloat(invPend.TOTAL_FATURA);
          totalCartTemp += parseFloat(invPend.C1_TOTAL_CORRIGIDO);
          if(invPend.FATURA_ID){
            invoicesIdTemp.push(invPend.FATURA_ID);
          }
        })
      }
      setPenalty(penaltTemp);
      setFees(feesTemp);
      setCorrection(correctionTemp);
      setOriginalValue(originalValueTemp);
      setTotalCart(totalCartTemp);
      setInvoicesId(invoicesIdTemp);
    }else{
      setPenalty(0);
      setFees(0);
      setCorrection(0);
      setOriginalValue(0);
      setTotalCart(0);
      setInvoicesId([])
    } */
  }

  function handlePaymentButton(invoice: InvoiceValue): void {
    const resp = invoicesId?.filter((inv) => {
      return inv !== invoice.invoice_id
    })
    if (resp) {
      if (resp?.length === invoicesId?.length) {
        setTotalCart(totalCart + (invoice.totalPaymentDue as number))
        setOriginalValue(originalValue + (invoice.totalPaymentDue as number))

        if (invoice.invoice_id)
          setInvoicesId([...invoicesId, invoice.invoice_id])
      } else {
        if (totalCart - (invoice.totalPaymentDue as number) <= 1) {
          setPenalty(0)
          setFees(0)
          setCorrection(0)
          setOriginalValue(0)
          setTotalCart(0)
        } else {
          setOriginalValue(originalValue - (invoice.totalPaymentDue as number))
          setTotalCart(totalCart - (invoice.totalPaymentDue as number))
        }
        setInvoicesId(resp)
      }
    } else {
      setTotalCart(invoice.totalPaymentDue as number)
      setOriginalValue(invoice.totalPaymentDue as number)
      if (invoice.invoice_id) setInvoicesId([invoice.invoice_id])
    }
  }

  function checkIdList(invoiceIdparse: number | null): boolean {
    if (invoiceIdparse) {
      const resp = invoicesId?.filter((invoice) => {
        return invoice === invoiceIdparse
      })
      if (resp)
        if (resp?.length > 0) {
          return true
        }
    }
    return false
  }

  function generatingTextStatus(inv: InvoiceValue): string {
    if (inv.paymentDueDate) {
      const d1 = new Date()
      const d2 = new Date(inv.paymentDueDate)
      if (!checkDueDate(inv.paymentDueDate)) {
         return 'Vencida'
      } 

     
    }
    return 'Aberto'
  }

  function generatingColorStatus(inv: InvoiceValue): string {
    if (inv.paymentDueDate) {
      const d1 = new Date()
      const d2 = new Date(inv.paymentDueDate)

      if(!checkDueDate(inv.paymentDueDate) ){
        return "#D93B3C";
      } 

      
    }

    return getColorSituation(inv)

   
  }

  /*


  function getTextSituation(situation: InvoiceDTO):string{
    if(situation.NUM_LOTE_ARREC !== null && ["TRAN", "ABER", "REAV"].includes(situation.SIT_FATURA_EDT)){
      return "PROCESSANDO"
    }
    const situationText = [
      {nome: "TRAN", text: "VENCIDA"},
      {nome: "ABER", text: "A VENCER"},
      {nome: "REAV", text: "A VENCER"},
      {nome: "PAGR", text: "PAGA"},
      {nome: "PAGA", text: "PAGA"},
      {nome: "PROC", text: "APROVADO"},
      {nome: "CONF", text: "APROVADO"},
    ]
    const respoSituation = situationText.filter(text => text.nome === situation.SIT_FATURA_EDT)[0].text;
    if(respoSituation){
      return respoSituation;
    }
    return "SITUAÇÃO";
  }
*/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getColorSituation(situation: InvoiceValue): string {
    return 'GRAY'

    /* if(situation.NUM_LOTE_ARREC !== null && ["TRAN", "ABER", "REAV"].includes(situation.SIT_FATURA_EDT)){
      return 'var(--secondaryColor)'
    }
    const colors = [
      {id: "TRAN", name: "#DC3545" },
      {id: "ABER", name: "#FB8C24" },
      {id: "REAV", name: "#FB8C24" },
      {id: "PAGR", name: "var(--primaryColorDark)" },
      {id: "PAGA", name: "var(--primaryColorDark)" },
      {id: "PROC", name: "var(--secondaryColor)" },
      {id: "CONF", name: "var(--secondaryColor)" },
    ];
    const respoColor = colors.filter(color => color.id === situation.SIT_FATURA_EDT)[0].name;
    if(respoColor){
      return respoColor;
    }
    return "GRAY"; */
  }
  /*
  function getMonthInvoice(month: string):string{
    const months = [
      {id: "01", name: "JAN" },
      {id: "02", name: "FEV" },
      {id: "03", name: "MAR" },
      {id: "04", name: "ABR" },
      {id: "05", name: "MAI" },
      {id: "06", name: "JUN" },
      {id: "07", name: "JUL" },
      {id: "08", name: "AGO" },
      {id: "09", name: "SET" },
      {id: "10", name: "OUT" },
      {id: "11", name: "NOV" },
      {id: "12", name: "DEZ" },
    ];
    const resultMonth = months.filter(monthObj => monthObj.id === month)[0].name;
    if(resultMonth){
      return resultMonth;
    }
    return "MÊS";
  }
*/
  function getSituation(situation: InvoiceValue): number {
    if (situation.paymentStatus) {
      return 1
    }
    return 0

    /*
    if(situation.NUM_LOTE_ARREC !== null && ["TRAN", "ABER", "REAV"].includes(situation.SIT_FATURA_EDT)){
      return 5;
    }
    if(situation.CAUC_061_DT_FIM_VIGENCIA !== null && ["TRAN", "ABER", "REAV"].includes(situation.SIT_FATURA_EDT)){
      if(situation.CAUC_061_DT_FIM_VIGENCIA.length < 10){
        const dateConf = `${situation.CAUC_061_DT_FIM_VIGENCIA.substr(0,6)}20${situation.CAUC_061_DT_FIM_VIGENCIA.substr(6,8)}`;
        if(checkDueDate(dateConf)){
          return 6;
        }
      }else if(situation.CAUC_061_DT_FIM_VIGENCIA.length === 10){
        if(checkDueDate(situation.CAUC_061_DT_FIM_VIGENCIA)){
          return 6;
        }
      }
    }

    const situationsInvoices = [
      {id: 1, name: "TRAN", type: 2},
      {id: 2, name: "ABER", type: 2},
      {id: 3, name: "REAV", type: 2},
      {id: 4, name: "PAGR", type: 4},
      {id: 5, name: "PAGA", type: 4},
      {id: 6, name: "PROC", type: 5},
      {id: 7, name: "CONF", type: 5},
    ];
    const respoSituation = situationsInvoices.filter(sit => sit.name === situation.SIT_FATURA_EDT)[0].type;
    if(respoSituation){
      return respoSituation;
    }
    return 0; */
  }

  async function paymentHandle(): Promise<void> {
    // console.log(invoicesId);

    // return;

    setLoading(true)
    const token = sessionStorage.getItem('token')
    const temp = localStorage.getItem('Invoices')
    let client
    if (temp && token) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      client = JSON.parse(temp || '')

      const invoicesList: any = []

      invoicesId?.forEach((ii) => {
        invoicesList.push({ id: ii })
      })

      try {
        if (invoices) {
          const { data } = await newApi.post(
            '/mobile/payment',
            {
              merchant: process.env.REACT_APP_MERCHANT_FIXED,
              doc: '',
              invoices: invoicesList,
            },
            {
              headers: {
                Authorization: `Basic ${token}`,
                'Content-Type': 'application/json',
              },
            }
          )

          if (data) {
            //  console.log(data)
            setLoading(false)
            history.push({
              pathname: '/payment',
              state: {
                data,
              },
            })
          }
        }
      } catch (error) {
        // console.log(error)
      }
    }
  }
  /*
  async function handlePay(): Promise<void>{
    setLoading(true);
    const temp = localStorage.getItem('info');
    const token = sessionStorage.getItem('token');
    let cliente: User;
    if(temp && token){
      cliente = JSON.parse(temp);
      try {
        if(invoices){
          const { data } = await api.post('/pagamento', {
            cliente: cliente?.NOME_CLIENTE,
            uc: cliente?.IDENT_UC,
            faturas: invoicesId,
            doc: cliente?.NUMERO_DOCTO_1
          }, {
            headers: {
              Authorization: `Basic ${token}`,
              "X-Forwarded-Host": window.location.hostname||process.env.REACT_APP_HOST
            }
          });
          if(data){
            setLoading(false)
            history.push({
              pathname: "/payment",
              state: {
                data
              }
            });
          }
        }
      } catch (error) {
        setLoading(false)
        history.push("/login");
      }
    }else{
      setLoading(false)
      history.push("/login");
    }
  }
*/
  function handleShowDetails(notaFiscal: string): void {
    if (showDetails === notaFiscal) {
      setShowDetails(undefined)
    } else {
      setShowDetails(notaFiscal)
    }
  }

  function downloadPdf(invoice: InvoiceValue): void {
    setShowInfoDownloadFile(true)
    setTimeout(() => {
      setDownloadThis(invoice)
      setShowInfoDownloadFile(false)
    }, THREE_SECONDS)
    setDownloadThis(undefined)
    if (donwloadIdInvoice === invoice.id) {
      setIdInvoice(undefined)
    } else {
      setIdInvoice(invoice.id)
    }
  }

  function transformDate(d: string): string {
    const dSplit = d.split('-')

    return `${dSplit[2]}/${dSplit[1]}/${dSplit[0]}`

    return ''
  }

  return (
    <Container>
      <div style={{ display: 'none', opacity: 0 }}>
        {downloadThis && (
          <PaymentSlip idInvoice={donwloadIdInvoice} invoice={downloadThis} />
        )}
      </div>
      <Box>
        <BoxInfoDownload
          style={{
            opacity: !showInfoDownloadFile ? 0 : 1,
            bottom: !showInfoDownloadFile ? 0 : 40,
            transition: '0.5s ease',
            padding: 0,
            display: 'none',
          }}
        >
          <Lottie
            style={{ width: 20, height: 20, margin: 0, padding: 0 }}
            options={{
              autoplay: true,
              loop: true,
              animationData: downloadLottie,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            isStopped={!showInfoDownloadFile}
            height={20}
            width={20}
          />
          <span>Salvando Fatura</span>
        </BoxInfoDownload>
        <BoxInfoCopy
          style={{
            opacity: !showInfo ? 0 : 1,
            bottom: !showInfo ? 0 : 40,
            transition: 'all 0.5s ease',
            padding: 0,
            display: 'none',
          }}
        >
          <Lottie
            style={{
              width: 20,
              height: 20,
              margin: '0px 0px 0px 0px',
              padding: 0,
            }}
            options={{
              autoplay: true,
              loop: true,
              animationData: copyLottie,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            isStopped={!showInfo}
            height={20}
            width={20}
          />
          <span>Código de barras copiado</span>
        </BoxInfoCopy>
        <Left>
          <div
            className="leftHeader"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <Title>Resumo de Faturas a pagar</Title>

            <button
              type="button"
              onClick={() => checkAllInvoices(!markAll)}
              className="leftSelectAll"
              style={{
                border: 'none',
                background: 'transparent',
              }}
            >
              <input hidden type="checkbox" id="checkBox" checked={markAll} />
              <div
                style={{
                  transition: '0.2s',
                  border: '2px solid var(--secondaryColor)',
                  height: 20,
                  width: 20,
                  borderRadius: '50%',
                  background: markAll ? 'var(--secondaryColor)' : '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  color="#fff"
                  style={{ height: 12 }}
                />
              </div>
              <span style={{ marginRight: 25, marginLeft: 10 }}>
                Selecionar todas as faturas (
                {invoices ? invoices.length : ''}
                {/* invoices?.filter(inv => ["ABER", "REAV", "TRAN"].includes(inv.SIT_FATURA_EDT) && getSituation(inv) !== 6).length */}
                )
              </span>
            </button>
          </div>
          {!loadingData ? (
            invoices && invoices.length > 0 ? (
              /* invoices?.filter(inv => ["ABER", "CONF", "REAV", "TRAN"].includes(inv.SIT_FATURA_EDT) && getSituation(inv) !== 6) && invoices?.filter(inv => ["ABER", "CONF", "REAV", "TRAN"].includes(inv.SIT_FATURA_EDT)).length > 0 */
              <BoxInvoicesPending
                style={{
                  marginBottom: totalCart <= 0 ? 0 : 70,
                  transition: '0.3s',
                }}
              >
                {invoices?.map((invoiceItem) => {
                  if (true) {
                    return (
                      <div
                        className="InvoiceItem"
                        key={invoiceItem.id}
                        style={{
                          overflow: 'hidden',
                          /* height: 145, */ borderRadius: 8,
                          backgroundColor: !(
                            showInfoDownloadFile &&
                            donwloadIdInvoice === invoiceItem.id
                          )
                            ? '#fff'
                            : 'var(--secondaryColor)',
                          position: 'relative',
                          maxWidth: 283,
                          maxHeight: 160,
                          top: 0,
                          height: 145,
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          width:
                            showInfoDownloadFile &&
                            donwloadIdInvoice === invoiceItem.id
                              ? 283
                              : undefined,
                          display:
                            showInfoDownloadFile &&
                            donwloadIdInvoice === invoiceItem.id
                              ? 'flex'
                              : undefined,
                          alignItems:
                            showInfoDownloadFile &&
                            donwloadIdInvoice === invoiceItem.id
                              ? 'center'
                              : undefined,
                          transition: '0.5',
                        }}
                      >
                        <BoxInvoiceRight
                          id="invoiceBox"
                          style={{
                            top: 0,
                            transition: '0.5s',
                            display:
                              showInfoDownloadFile &&
                              donwloadIdInvoice === invoiceItem.id
                                ? 'none'
                                : 'block',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexDirection: 'row',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <span>{`Fatura ${invoiceItem.refDate}`}</span>
                              <div
                                data-tip
                                data-for={`infoSituationInvoices${invoiceItem.id}`}
                                style={{
                                  width: 12,
                                  height: 12,
                                  borderRadius: 20,
                                  background: generatingColorStatus(
                                    invoiceItem
                                  ),
                                  marginLeft: 10,
                                }}
                              />
                              <ReactTooltip
                                id={`infoSituationInvoices${invoiceItem.id}`}
                                place="top"
                                type="dark"
                                effect="solid"
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 5,
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  {generatingTextStatus(invoiceItem)}
                                </div>
                              </ReactTooltip>
                            </div>
                            <div
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              {/* (getSituation(invoiceItem) === 2 || getSituation(invoiceItem) === 6) && (
                                  <button data-tip data-for="buttonTextCopy" type='button' onClick={()=>{copyBarCode(invoiceItem)}}>
                                    <img src={iconBarCode} alt="Código de barras" />
                                    <ReactTooltip id='buttonTextCopy' place="top" type="dark" effect="solid">
                                      <div style={{display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'flex-start'}}>
                                        <h4>
                                          COPIAR CÓDIGO
                                        </h4>
                                      </div>
                                    </ReactTooltip>
                                  </button>
                                ) */}
                              <div>
                                <button
                                  data-tip
                                  data-for="buttonTextDownload"
                                  type="button"
                                  onClick={() => {
                                    downloadPdf(invoiceItem)
                                  }}
                                >
                                  {!(
                                    showInfoDownloadFile &&
                                    donwloadIdInvoice === invoiceItem.id
                                  ) ? (
                                    <img
                                      src={iconDownload}
                                      alt="Download Fatura"
                                      style={{ color: 'red' }}
                                    />
                                  ) : (
                                    'carregando'
                                  )}
                                  <ReactTooltip
                                    id="buttonTextDownload"
                                    place="top"
                                    type="dark"
                                    effect="solid"
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 5,
                                        alignItems: 'flex-start',
                                      }}
                                    >
                                      <h4>BAIXAR VIA PARA PAGAMENTO</h4>
                                    </div>
                                  </ReactTooltip>
                                </button>
                              </div>
                            </div>
                          </div>
                          <span className="nameClient">
                            {invoiceItem.customer}
                          </span>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span className="valueAndDate">
                              {`R$ ${invoiceItem.totalPaymentDue?.toLocaleString(
                                'pt-BR',
                                { minimumFractionDigits: 2 }
                              )}`}
                            </span>
                            <span className="valueAndDate">
                              {`VENC. ${
                                /* invoiceItem.DT_VENCIMENTO_EDT */ invoiceItem.paymentDueDate
                                  ? transformDate(invoiceItem.paymentDueDate)
                                  : ''
                              }`}
                            </span>
                          </div>
                          {
                            /* getSituation(invoiceItem) === 0 ?
                            (
                              <div
                                data-tip
                                data-for='buttonDontPaymentOnline'
                              >
                                <button
                                  disabled
                                  type="button"
                                  className="btnOpen"
                                >
                                  EM ABERTO
                                </button>
                                <ReactTooltip id='buttonDontPaymentOnline' place="top" type="dark" effect="solid">
                                  <div style={{display: 'flex', flexDirection: 'column', gap: 5, alignItems: 'flex-start', zIndex: 20}}>
                                    <h4>
                                      FATURA NÃO ELEGÍVEL PARA PAGAMENTO ONLINE NO CARTÃO DE CRÉDITO
                                    </h4>
                                  </div>
                                </ReactTooltip>
                              </div>
                              )
                              : */ getSituation(
                              invoiceItem
                            ) === 0 ? (
                              <button
                                style={{
                                  background: checkIdList(
                                    invoiceItem.invoice_id
                                  )
                                    ? 'var(--secondaryColor)'
                                    : '#EDF1F5',
                                  color: checkIdList(invoiceItem.invoice_id)
                                    ? '#FFF'
                                    : 'var(--secondaryColor)',
                                  marginTop: 5,
                                  transition: 'all .5s ease',
                                }}
                                type="button"
                                onClick={() => {
                                  handlePaymentButton(invoiceItem)
                                }}
                                className="btnConfirm"
                              >
                                {checkIdList(invoiceItem.invoice_id) ? (
                                  <div>
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      style={{ marginRight: 10 }}
                                    />
                                    FATURA SELECIONADA
                                  </div>
                                ) : (
                                  'SELECIONAR FATURA'
                                )}
                              </button>
                            ) : null
                          }
                        </BoxInvoiceRight>
                        <div
                          style={{
                            // display: getSituation(invoiceItem) !== 2 && getSituation(invoiceItem) !== 6 ? 'flex' : 'none' ,
                            display:
                              getSituation(invoiceItem) === 1 &&
                              !(
                                showInfoDownloadFile &&
                                donwloadIdInvoice === invoiceItem.id
                              )
                                ? 'flex'
                                : 'none',
                            width: '100%',
                            background:
                              showInfoDownloadFile &&
                              donwloadIdInvoice === invoiceItem.id
                                ? ''
                                : '#E6F2CE',
                            height: 50,
                            padding: 10,
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              gap: 10,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <img
                              src={iconInfoGreen}
                              alt="Infomação"
                              style={{ height: 24 }}
                            />
                            <span
                              style={{
                                color: '#494949',
                                fontSize: 11,
                                lineHeight: '14px',
                                fontWeight: 400,
                              }}
                            >
                              O pagamento desta fatura foi aprovado e será
                              validado em até 48h úteis.
                            </span>
                          </div>
                        </div>

                        <div
                          style={{
                            color: '#FFFFFF',
                            margin: 'auto',
                            display:
                              showInfoDownloadFile &&
                              donwloadIdInvoice === invoiceItem.id
                                ? 'block'
                                : 'none',
                          }}
                        >
                          BAIXANDO FATURA
                        </div>
                      </div>
                    )
                  }
                  return (
                    <div
                      className="InvoiceItem"
                      key={invoiceItem.id}
                      style={{
                        overflow: 'hidden',
                        /* height: 145, */ borderRadius: 8,
                        backgroundColor: 'var(--secondaryColor)',
                        position: 'relative',
                        maxWidth: 283,
                        maxHeight: 160,
                        top: 0,
                        height: 145,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: 283,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{ color: '#FFFFFF', margin: 'auto' }}>
                        BAIXANDO FATURA
                      </div>
                    </div>
                  )
                })}
              </BoxInvoicesPending>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={noInvoicesPendIcon}
                  alt="Todas as faturas foram pagas"
                />
                <span
                  style={{
                    fontFamily: 'Roboto',
                    color: '#2B2B2B',
                    fontSize: 36,
                    fontWeight: 500,
                    marginTop: 20,
                  }}
                >
                  Parabéns!!
                </span>
                <span
                  style={{
                    fontFamily: 'Roboto',
                    color: '#2B2B2B',
                    fontSize: 24,
                    fontWeight: 400,
                  }}
                >
                  Nenhuma fatura pendente!
                </span>
              </div>
            )
          ) : (
            // Shimmer Efects
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <BoxInvoiceRight
                style={{ marginTop: 20, marginRight: 10, borderRadius: 8 }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Skeleton
                      style={{
                        width: 150,
                        height: 10,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Skeleton
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 8,
                        marginBottom: 7,
                        marginRight: 10,
                      }}
                    />
                    <Skeleton
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 8,
                        marginBottom: 7,
                      }}
                    />
                  </div>
                </div>
                <span className="nameClient">
                  <Skeleton
                    style={{
                      width: 80,
                      height: 10,
                      marginBottom: 7,
                    }}
                  />
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <span className="valueAndDate">
                    <Skeleton
                      style={{
                        width: 80,
                        height: 10,
                        marginBottom: 5,
                      }}
                    />
                  </span>
                  <span className="valueAndDate">
                    <Skeleton
                      style={{
                        width: 80,
                        height: 10,
                        marginBottom: 20,
                      }}
                    />
                  </span>
                </div>
                <SkeletonGreen
                  style={{
                    width: '100%',
                    height: 35,
                    borderRadius: 8,
                    marginBottom: 12,
                  }}
                />
              </BoxInvoiceRight>
              <BoxInvoiceRight
                style={{ marginTop: 20, marginRight: 10, borderRadius: 8 }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Skeleton
                      style={{
                        width: 150,
                        height: 10,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Skeleton
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 8,
                        marginBottom: 7,
                        marginRight: 10,
                      }}
                    />
                    <Skeleton
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 8,
                        marginBottom: 7,
                      }}
                    />
                  </div>
                </div>
                <span className="nameClient">
                  <Skeleton
                    style={{
                      width: 80,
                      height: 10,
                      marginBottom: 7,
                    }}
                  />
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <span className="valueAndDate">
                    <Skeleton
                      style={{
                        width: 80,
                        height: 10,
                        marginBottom: 5,
                      }}
                    />
                  </span>
                  <span className="valueAndDate">
                    <Skeleton
                      style={{
                        width: 80,
                        height: 10,
                        marginBottom: 20,
                      }}
                    />
                  </span>
                </div>
                <SkeletonGreen
                  style={{
                    width: '100%',
                    height: 35,
                    borderRadius: 8,
                    marginBottom: 12,
                  }}
                />
              </BoxInvoiceRight>
              <BoxInvoiceRight style={{ marginTop: 20, borderRadius: 8 }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Skeleton
                      style={{
                        width: 150,
                        height: 10,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Skeleton
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 8,
                        marginBottom: 7,
                        marginRight: 10,
                      }}
                    />
                    <Skeleton
                      style={{
                        width: 35,
                        height: 35,
                        borderRadius: 8,
                        marginBottom: 7,
                      }}
                    />
                  </div>
                </div>
                <span className="nameClient">
                  <Skeleton
                    style={{
                      width: 80,
                      height: 10,
                      marginBottom: 7,
                    }}
                  />
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <span className="valueAndDate">
                    <Skeleton
                      style={{
                        width: 80,
                        height: 10,
                        marginBottom: 5,
                      }}
                    />
                  </span>
                  <span className="valueAndDate">
                    <Skeleton
                      style={{
                        width: 80,
                        height: 10,
                        marginBottom: 20,
                      }}
                    />
                  </span>
                </div>
                <SkeletonGreen
                  style={{
                    width: '100%',
                    height: 35,
                    borderRadius: 8,
                    marginBottom: 12,
                  }}
                />
              </BoxInvoiceRight>
            </div>
          )}
          <BoxPrice
            style={{
              opacity: totalCart <= 0 ? 0 : 1,
              bottom: totalCart <= 0 ? '0px' : '50px',
              transition: '0.5s ease',
              padding: 10,
              cursor: totalCart <= 0 ? 'default' : '',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: 10,
                flexDirection: 'row',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  width: 227,
                  height: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 8,
                  background: '#EDF1F5',
                }}
              >
                {invoicesId?.length &&
                  `FATURA${invoicesId?.length > 1 ? 'S' : ''} SELECIONADA${
                    invoicesId?.length > 1 ? 'S' : ''
                  } - ${invoicesId?.length}`}
              </span>
              <div
                style={{
                  justifyContent: 'center',
                  borderRadius: 8,
                  display: 'flex',
                  width: 227,
                  height: 40,
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 10,
                  background: '#EDF1F5',
                }}
              >
                {`TOTAL - R$ ${totalCart.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}`}
                <img
                  style={{
                    opacity: originalValue !== totalCart ? 1 : 0,
                    display: originalValue !== totalCart ? 'flex' : 'none',
                    transition: 'opacity 0.3s',
                    height: 20,
                  }}
                  data-tip
                  data-for="infoFees"
                  src={iconInfoGreen}
                  alt="Juros e multa"
                />
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                paymentHandle()
              }}
              disabled={totalCart <= 0 || loading}
            >
              {loading ? (
                <img
                  src={loadingIcon}
                  alt="Carregando"
                  style={{ height: 40, margin: 0 }}
                />
              ) : (
                <>
                  <span>INICIAR PAGAMENTO</span>
                  <FontAwesomeIcon icon={faArrowRight} color="#fff" />
                </>
              )}
            </button>
          </BoxPrice>

          <BoxPriceMobile
            onClick={() => {
              paymentHandle()
            }}
            disabled={totalCart <= 0}
            style={{
              zIndex: totalCart <= 0 ? 10 : 20,
              opacity: totalCart <= 0 ? 0 : 1,
              cursor: totalCart <= 0 ? 'default' : '',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <span>
                {invoicesId?.length
                  ? `${invoicesId?.length} FATURA${
                      invoicesId?.length > 1 ? 'S' : ''
                    }`
                  : ''}
              </span>
              <span style={{ fontSize: 19, fontWeight: 600 }}>
                {`R$ ${totalCart.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}`}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                overflow: 'hidden',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <img
                style={{
                  overflow: 'hidden',
                  opacity: originalValue !== totalCart ? 1 : 0,
                  transition: 'opacity 0.3s',
                }}
                data-tip
                data-for="infoFees"
                src={iconInfoGreen}
                alt="Juros e multa"
              />
              <span style={{ fontSize: 19, fontWeight: 600, marginRight: 10 }}>
                PAGAR
              </span>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 34,
                  width: 34,
                  borderRadius: 8,
                  backgroundColor: '#ECF1DF',
                }}
              >
                {loading ? (
                  <img
                    src={loadingPrimaryIcon}
                    alt="Carregando"
                    style={{ height: 30, margin: 0 }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    color="var(--primaryColorDark)"
                  />
                )}
              </div>
            </div>
          </BoxPriceMobile>
        </Left>

        <Right>
          <Title>
            {/* <span className="title">
              Todas as faturas (
              {invoices?.length || 0}
              )
              </span> */}
            {
              /* invoices?.filter(inv => getSituation(inv) !== 2 ).length && invoices?.filter(inv => getSituation(inv) === 2 ).length > 0 */
              true ? (
                <button
                  className="allInvoiceMobile"
                  type="button"
                  // onClick={()=> {console.log(yearsInvoices)}}
                  onClick={() => checkAllInvoices(!markAll)}
                  style={{ border: 'none', background: 'transparent' }}
                >
                  <input
                    hidden
                    type="checkbox"
                    id="checkBox"
                    checked={markAll}
                  />
                  <div
                    style={{
                      transition: '0.2s',
                      background: markAll ? 'var(--secondaryColor)' : '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 24,
                      marginTop: 7,
                      width: 24,
                      minHeight: 24,
                      borderRadius: '50%',
                      border: '2px solid var(--secondaryColor)',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCheck}
                      color="#fff"
                      style={{ height: 14 }}
                    />
                  </div>
                  <span
                    style={{
                      marginLeft: 10,
                      fontFamily: 'Roboto',
                      fontWeight: 'bold',
                      fontSize: 20,
                      color: '#064076',
                      whiteSpace: 'pre-wrap',
                      textAlign: 'left',
                    }}
                  >
                    Meus Débitos
                    <span
                      style={{
                        fontWeight: 'normal',
                        fontSize: 11,
                        textAlign: 'left',
                      }}
                    >
                      {'\n'}
                      {invoices ? invoices.length : ''}
                      {' FATURAS ABERTAS'}
                    </span>
                  </span>
                </button>
              ) : null
            }
          </Title>
          <BoxAllInvoices>
            {!loadingData ? (
              yearsInvoices?.map((year) => {
                return (
                  <div id="ScrollContainer">
                    <YearTitle key={year}>{year}</YearTitle>
                    {invoices?.map((invoiceItem) => {
                      if (parseFloat(invoiceItem.refDate.substr(3)) === year) {
                        return (
                          <Invoice
                            key={invoiceItem.id}
                            style={{
                              transition: 'all 0.4s',

                              height:
                                showDetails === invoiceItem.id
                                  ? 230
                                  : 90 /*  showDetails === invoiceItem.barcode
                      ? getSituation(invoiceItem) === 4 || getSituation(invoiceItem) === 5
                        ? 260
                        : 295
              : 90  */,
                            }}
                          >
                            <button /* disabled={getSituation(invoiceItem) !== 2} */
                              onClick={() => {
                                handlePaymentButton(invoiceItem)
                              }}
                              type="button"
                              style={{
                                border: 'none',
                                background: 'white',
                                maxWidth: 74,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                paddingRight: 24,
                                marginLeft: -10,
                              }}
                            >
                              <span style={{ fontSize: 11, fontWeight: 400 }}>
                                {
                                  invoiceItem.refDate
                                  // getMonthInvoice(invoiceItem.DT_REF_EDT.substr(0,2))
                                }
                              </span>
                              <div
                                style={{
                                  transition: '0.2s',
                                  background: checkIdList(
                                    invoiceItem.invoice_id
                                  )
                                    ? 'var(--secondaryColor)'
                                    : '#fff',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: 24,
                                  marginTop: 7,
                                  width: 24,
                                  minHeight: 24,
                                  borderRadius: '50%',
                                  border: '2px solid var(--secondaryColor)',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  color="#fff"
                                  style={{ height: 14 }}
                                />
                              </div>
                              {/* getSituation(invoiceItem) === 2
                          && (
                            
                          ) */}
                              <div
                                style={{
                                  background: '#C1C2C2',
                                  marginTop: 7,
                                  width: 0.5,
                                  height: '100%',
                                }}
                              />
                            </button>
                            <button
                              onClick={() => {
                                handleShowDetails(invoiceItem.id)
                              }}
                              type="button"
                              style={{
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                border: 'none',
                                borderBottom: '0.5px solid #C1C2C2',
                                display: 'flex',
                                background: 'transparent',
                                flexDirection: 'column',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  width: '100%',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    margin: 0,
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      fontWeight: 500,
                                      color: '#494949',
                                    }}
                                  >
                                    {
                                      `Fatura ${invoiceItem.refDate}
                                `
                                      // invoiceItem.DT_REF_EDT + (invoiceItem.FD !== '0' ? ` - ${invoiceItem.FD}` : '')}

                                      // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                  </span>
                                  <span
                                    style={{
                                      color: '#858585',
                                      fontSize: 13,
                                      fontWeight: 400,
                                      maxWidth: '100%',
                                      textAlign: 'start',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {`VENC. ${
                                      invoiceItem.paymentDueDate
                                        ? transformDate(
                                            invoiceItem?.paymentDueDate
                                          )
                                        : ''
                                    }`}
                                    <br />
                                    {`${
                                      invoiceItem.customer?.substr(0, 13) ||
                                      'SEM NOME'
                                    }...`}
                                  </span>
                                  <span
                                    style={{
                                      color: '#494949',
                                      fontSize: 15,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {
                                      `R$ ${invoiceItem.totalPaymentDue?.toLocaleString(
                                        'pt-BR',
                                        { minimumFractionDigits: 2 }
                                      )}`
                                      // `R$ ${parseFloat(invoiceItem.C1_TOTAL_CORRIGIDO).toLocaleString('pt-BR', {minimumFractionDigits: 2})}`
                                      // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                  </span>
                                </div>
                                <div
                                  style={{
                                    maxHeight: 23,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '0px 15px',
                                    marginRight: 15,
                                    borderRadius: 30,
                                    background: generatingColorStatus(
                                      invoiceItem
                                    ),
                                  }}
                                >
                                  <span
                                    style={{
                                      color: '#FFF',
                                      fontSize: 10,
                                      fontWeight: 600,
                                      lineHeight: 0,
                                    }}
                                  >
                                    {generatingTextStatus(invoiceItem)}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  paddingRight: 15,
                                  transition: '0.3s',
                                  marginTop: 10,
                                  width: '100%',
                                  height: 160,
                                  // showDetails === invoiceItem.NOTA_FISCAL ? 160 : 0
                                  opacity: 1,
                                  // showDetails === invoiceItem.NOTA_FISCAL ? 1 : 0
                                }}
                              >
                                {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <span style={{fontSize: 13, fontWeight: 400, color: '#858585'}}>Juros</span>
                            <span style={{fontSize: 13, fontWeight: 400, color: '#858585'}}>{`R$ ${parseFloat(invoiceItem.C1_JUROS).toLocaleString('pt-BR', {minimumFractionDigits: 2})}`}</span>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <span style={{fontSize: 13, fontWeight: 400, color: '#858585'}}>Multa</span>
                            <span style={{fontSize: 13, fontWeight: 400, color: '#858585'}}>{`R$ ${parseFloat(invoiceItem.C1_MULTA).toLocaleString('pt-BR', {minimumFractionDigits: 2})}`}</span>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <span style={{fontSize: 13, fontWeight: 400, color: '#858585'}}>Correção</span>
                            <span style={{fontSize: 13, fontWeight: 400, color: '#858585'}}>{`R$ ${parseFloat(invoiceItem.C1_CORRECAO).toLocaleString('pt-BR', {minimumFractionDigits: 2})}`}</span>
                    </div> */}
                                <div
                                  style={{
                                    marginBottom: 15,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 600,
                                      color: '#858585',
                                    }}
                                  >
                                    Valor Original
                                  </span>
                                  <span
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 600,
                                      color: '#858585',
                                    }}
                                  >
                                    {`R$ ${
                                      invoiceItem.totalPaymentDue?.toLocaleString(
                                        'pt-BR',
                                        { minimumFractionDigits: 2 }
                                      )
                                      // parseFloat(invoiceItem.TOTAL_FATURA).toLocaleString('pt-BR', {minimumFractionDigits: 2})
                                    }`}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    height: 40,
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <button
                                    onClick={() => {
                                      copyBarCode(invoiceItem)
                                    }}
                                    style={{
                                      marginRight: 15,
                                      background: '#EDF1F5',
                                      width: '100%',
                                      borderRadius: 8,
                                      height: 35,
                                      border: 'none',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                    type="button"
                                  >
                                    <span
                                      style={{
                                        color: '#064076',
                                        fontSize: 11,
                                        fontWeight: 500,
                                      }}
                                    >
                                      COPIAR CÓDIGO
                                    </span>
                                  </button>
                                  {/* (getSituation(invoiceItem) === 2 || getSituation(invoiceItem) === 6)
                            && (
                           
                            ) */}
                                  <button
                                    type="button"
                                    onClick={() => {
                                      downloadPdf(invoiceItem)
                                    }}
                                    style={{
                                      background: '#EDF1F5',
                                      width: '100%',
                                      borderRadius: 8,
                                      height: 35,
                                      border: 'none',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: '#064076',
                                        fontSize: 11,
                                        fontWeight: 500,
                                      }}
                                    >
                                      VIA PAGAMENTO
                                    </span>
                                  </button>
                                </div>
                                <button
                                  onClick={() => {
                                    handlePaymentButton(invoiceItem)
                                  }}
                                  style={{
                                    marginTop: 5,
                                    width: '100%',
                                    background: '#064076',
                                    borderRadius: 8,
                                    height: 35,
                                    border: 'none',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  type="button"
                                >
                                  <span
                                    style={{
                                      color: '#fff',
                                      fontSize: 12,
                                      fontWeight: 500,
                                    }}
                                  >
                                    {checkIdList(invoiceItem.invoice_id) ? (
                                      <>
                                        <FontAwesomeIcon
                                          icon={faCheckCircle}
                                          style={{ marginRight: 10 }}
                                        />
                                        FATURA SELECIONADA
                                      </>
                                    ) : (
                                      'SELECIONAR FATURA'
                                    )}
                                  </span>
                                </button>
                                {/* getSituation(invoiceItem) === 2
                            && (
                              
                            ) */}
                                {/* <button onClick={()=>{handleShowDetails(invoiceItem.NOTA_FISCAL)}} type="button" style={{border: 'none', width: '100%', marginRight: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 35, bottom: 0, marginTop: 10, background: '#fff'}}>
                            <FontAwesomeIcon icon={faChevronUp} size="1x" color="var(--secondaryColor)" style={{ opacity: showDetails === invoiceItem.NOTA_FISCAL ? 1 : 0}} />
                          </button> */}
                              </div>
                            </button>
                          </Invoice>
                        )
                      }
                      return <div key={invoiceItem.id} />
                    })}
                  </div>
                )
              })
            ) : (
              // Shimmer Efects
              <>
                <div
                  style={{
                    width: '100%',
                    height: 50,
                    background: 'var(--secondaryColor)',
                  }}
                />
                <Invoice>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                      padding: '20px 20px 15px 0px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <Skeleton
                            style={{
                              width: 25,
                              height: 25,
                              borderRadius: '50%',
                              marginRight: 20,
                            }}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <Skeleton
                            style={{
                              width: 80,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <Skeleton
                      style={{
                        width: 80,
                        height: 25,
                        borderRadius: 16,
                      }}
                    />
                  </div>
                </Invoice>
                <Invoice>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                      padding: '20px 20px 15px 0px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <Skeleton
                            style={{
                              width: 25,
                              height: 25,
                              borderRadius: '50%',
                              marginRight: 20,
                            }}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <Skeleton
                            style={{
                              width: 80,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <Skeleton
                      style={{
                        width: 80,
                        height: 25,
                        borderRadius: 16,
                      }}
                    />
                  </div>
                </Invoice>
                <Invoice>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                      padding: '20px 20px 15px 0px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <Skeleton
                            style={{
                              width: 25,
                              height: 25,
                              borderRadius: '50%',
                              marginRight: 20,
                            }}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <Skeleton
                            style={{
                              width: 80,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <Skeleton
                      style={{
                        width: 80,
                        height: 25,
                        borderRadius: 16,
                      }}
                    />
                  </div>
                </Invoice>
                <Invoice>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                      padding: '20px 20px 15px 0px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <Skeleton
                            style={{
                              width: 25,
                              height: 25,
                              borderRadius: '50%',
                              marginRight: 20,
                            }}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <Skeleton
                            style={{
                              width: 80,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <Skeleton
                      style={{
                        width: 80,
                        height: 25,
                        borderRadius: 16,
                      }}
                    />
                  </div>
                </Invoice>
              </>
            )}
            <div id="mobile">
              <Footer />
            </div>
          </BoxAllInvoices>

          <BoxPriceMobile
            onClick={() => {
              paymentHandle()
            }}
            disabled={totalCart <= 0}
            style={{
              zIndex: totalCart <= 0 ? 10 : 20,
              opacity: totalCart <= 0 ? 0 : 1,
              cursor: totalCart <= 0 ? 'default' : '',
              position: totalCart <= 0 ? 'fixed' : 'relative',
              bottom: totalCart <= 0 ? -60 : 0,
              // display: totalCart <=0 ? 'none' : 'flex',

              // height: totalCart <=0 ? 0 : 60,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <span>
                {invoicesId?.length
                  ? `${invoicesId?.length} FATURA${
                      invoicesId?.length > 1 ? 'S' : ''
                    }`
                  : ''}
              </span>
              <span style={{ fontSize: 19, fontWeight: 600 }}>
                {`R$ ${totalCart.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}`}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                overflow: 'hidden',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <img
                style={{
                  overflow: 'hidden',
                  opacity: originalValue !== totalCart ? 1 : 0,
                  transition: 'opacity 0.3s',
                }}
                data-tip
                data-for="infoFees"
                src={iconInfoGreen}
                alt="Juros e multa"
              />
              <span style={{ fontSize: 19, fontWeight: 600, marginRight: 10 }}>
                PAGAR
              </span>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 34,
                  width: 34,
                  borderRadius: 8,
                  backgroundColor: '#ECF1DF',
                }}
              >
                {loading ? (
                  <img
                    src={loadingPrimaryIcon}
                    alt="Carregando"
                    style={{ height: 30, margin: 0 }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    color="var(--primaryColorDark)"
                  />
                )}
              </div>
            </div>
          </BoxPriceMobile>
        </Right>
      </Box>
      <ReactTooltip
        id="infoFees"
        disable={originalValue === totalCart}
        place="top"
        type="dark"
        effect="solid"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            alignItems: 'flex-start',
          }}
        >
          <h3>
            Valor original:
            <span>
              {` R$ ${originalValue.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}`}
            </span>
          </h3>
          <span>Devido ao atraso, serão cobrados:</span>
          <h4>
            {`Juros: R$ ${fees.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })}`}
          </h4>
          <h4>
            {`Multas: R$ ${penalty.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })}`}
          </h4>
          <h4>
            {`Correção: R$ ${correction.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })}`}
          </h4>
          <h3>
            Valor Atualizado:
            <span>
              {` R$ ${totalCart.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}`}
            </span>
          </h3>
        </div>
      </ReactTooltip>
      <div style={{ display: totalCart > 0 ? 'none' : 'block' }} id="web">
        <Footer />
      </div>
    </Container>
  )
}

export default connect((state: StateInitial) => ({ modules: state }))(Home)
