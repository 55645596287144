import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  top: 0px;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(0deg, var(--backgroundColorLight) 93%, var(--secondaryColor) 64px);
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  margin: 0;
  padding: 0;
`;
