/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react'
import jsPDF from 'jspdf'
import { useBarcode } from '@createnextapp/react-barcode'
import {
  Font,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFViewer,
} from '@react-pdf/renderer'
import { parseISO, format } from 'date-fns'

import pt from 'date-fns/locale/pt-BR'
import {
  InvoiceDTO,
  User,
  PaymentDTO,
  Invoice,
  PayDTO,
} from '../../utils/models'
import logoColored from '../../assets/images/logotipo-horizontal-colorido.jpg'
import logoMono from '../../assets/images/caer-logotipo-horizontal-monocromatico.png'
import pigzpayIconBlack from '../../assets/images/pigzpay-black.png'
import logoBill from '../../assets/images/logo-bill.png'
import logoBillWhite from '../../assets/images/bill-logo-white.png'
import pigzpayIcon from '../../assets/images/pigzpay-logotipo-colorido.png'
import arrowRight from '../../assets/images/arrow-right-short.png'
import dollarIcon from '../../assets/images/dollar.png'
import calendarIcon from '../../assets/images/calendar.png'
import whaterIcon from '../../assets/images/agua.png'
import {
  Container,
  Line,
  BlackLine,
  Column,
  Row,
  TableInvoices,
} from './styles'

import font from '../../assets/fonts/Roboto/Roboto-Bold.ttf'
import fontMedium from '../../assets/fonts/Roboto/Roboto-Medium.ttf'
import regular from '../../assets/fonts/Roboto/Roboto-Light.ttf'

interface InvDTO {
  invoice: InvoiceDTO
  idInvoice: string | undefined
}

interface newInvDTO {
  invoice: Invoice
  idInvoice: string | undefined
}

interface InvProofDTO {
  proof: PayDTO
}

export const PaymentSlip = ({ idInvoice, invoice }: newInvDTO): any => {
  const [client, setClient] = useState<Invoice>()

  useEffect(() => {
    const tempClient = localStorage.getItem('Invoices')
    if (tempClient) {
      const temp = JSON.parse(tempClient)
      setClient(temp)
      const divId = document.getElementById('pdfPrint') as HTMLElement
      const printDoc = new jsPDF('portrait', 'px', 'A4')
      printDoc.addFileToVFS(
        '../../assets/fonts/Roboto/Roboto-Medium.ttf',
        'Roboto'
      )
      if (divId && invoice.id === idInvoice) {
        printDoc.html(divId, {
          callback(pdf) {
            pdf.save(`Boleto_${invoice.refDate}.pdf`)
          },
        })
      }
    }
  }, [invoice, idInvoice])

  const { inputRef } = useBarcode({
    value: invoice ? (invoice.barcode === null ? '0' : invoice.barcode) : '0',
    options: {
      displayValue: false,
      background: '#fff',
    },
  })

  function ucFirstAllWords(str: string): string {
    const pieces = str.toLowerCase().split(' ')
    for (let i = 0; i < pieces.length; i += 1) {
      if (i === 1) {
        const j = pieces[i].charAt(0).toUpperCase()
        pieces[i] = `${j}.`
      } else {
        const j = pieces[i].charAt(0).toUpperCase()
        pieces[i] = j + pieces[i].substr(1)
      }
    }
    return pieces.join(' ')
  }

  function transformDate(d: string): string {
    const dSplit = d.split('-')

    return `${dSplit[2]}/${dSplit[1]}/${dSplit[0]}`

    return d
  }

  return (
    <Container id="pdfPrint" style={{ margin: 'auto', width: 450 }}>
      <div
        style={{ width: '100%', height: 10, background: 'var(--primaryColor)' }}
      />
      <div
        style={{
          padding: '15px 25px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Column
          style={{ alignItems: 'center', width: '100%', marginBottom: 25 }}
        >
          <Row>
            <Column>
              <img
                alt="Logo"
                height={30}
                width={120}
                src={logoColored}
                style={{ marginBottom: 10 }}
              />
              <span style={{ fontSize: 8, fontWeight: 300 }}>
                {process.env.REACT_APP_ADDRESS}
              </span>
              <span style={{ fontSize: 8, fontWeight: 300 }}>
                CNPJ: 
                {' '}
                {process.env.REACT_APP_CNPJ}
                {' '}
                · IE:
                {' '}
                {process.env.REACT_APP_IE}
              </span>
            </Column>
            <Column style={{ textAlign: 'end' }}>
              <span style={{ fontSize: 20, fontWeight: 800, lineHeight: 0.9 }}>
                Via para
                <br />
                Pagamento
              </span>
              <span style={{ fontSize: 10, fontWeight: 300, marginTop: 5 }}>
                ART. 123, § único
              </span>
            </Column>
          </Row>
        </Column>
        <Row>
          {/* <Column>
            <span style={{fontWeight: 500, fontSize: 13, width: '100%' }}>
              {ucFirstAllWords(client?.customer || '-').substr(0,20)}
            </span>
            <span style={{fontWeight: 600, fontSize: 12, marginBottom: 10}}>{`Matrícula ${invoice.ref || '-'}`}</span>
            <span style={{fontSize: 8, fontWeight: 300, lineHeight: 1.3}}>{`${client?.LOGR_TIPO_NOME || '-'} - ${client?.NOME_BAIRRO || '-'}`}</span>
            <span style={{fontSize: 8, fontWeight: 300, lineHeight: 1.3}}>{`${client?.NOME_LOCAL || '-'}`}</span>
            <span style={{fontSize: 8, fontWeight: 300, lineHeight: 1.3}}>{`CEP ${client?.CEP_LOGRADOURO || '-'}`}</span>
          </Column> */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
              padding: 5,
              width: 182,
              height: '100%',
              border: '1.5px solid var(--secondaryColor)',
              borderRadius: 8,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <div style={{width: '100%', alignItems: 'center', padding: 5, display: 'flex', justifyContent: 'space-between', background: '#F8F9F2', border: '1px solid var(--secondaryColor)', borderRadius: 6}}>
              <span style={{fontSize: 8}}>NOTA FISCAL</span>
              <span style={{fontSize: 10, fontWeight: 500}}>{`${invoice.NOTA_FISCAL || '-'}`}</span>
  </div> */}
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                padding: 5,
                display: 'flex',
                justifyContent: 'space-between',
                background: '#F8F9F2',
                border: '1px solid var(--secondaryColor)',
                borderRadius: 6,
              }}
            >
              <span style={{ fontSize: 8 }}>MÊS FATURADO</span>
              <span style={{ fontSize: 10, fontWeight: 500 }}>
                {`${invoice.refDate || '-'}`}
              </span>
            </div>
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                padding: 5,
                display: 'flex',
                justifyContent: 'space-between',
                background: '#F8F9F2',
                border: '1px solid var(--secondaryColor)',
                borderRadius: 6,
              }}
            >
              <span style={{ fontSize: 8 }}>VENCIMENTO</span>
              <span style={{ fontSize: 10, fontWeight: 500 }}>
                {`${transformDate(invoice.paymentDueDate || '') || '-'}`}
              </span>
            </div>
          </div>
        </Row>
        <Row style={{ marginTop: 30, alignItems: 'center' }}>
          {/* <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 20, background:'#F8F9F2', width: 120, height: 120, borderRadius: 16}}>
            <img src={whaterIcon} alt="Energia" height={30} />
            <span style={{fontSize: 8, marginTop: 5}}>seu consumo foi</span>
            <span style={{fontSize: 14, fontWeight: 500, marginTop: 10}}>
              {`${invoice.CONSUMO || '-'} m³`}
            </span>
</div> */}
          {/* <div style={{display: 'flex', position: 'absolute', background: 'var(--secondaryColor)', color: '#fff', borderRadius: '50%', width: 30, height: 30, alignItems: 'center', justifyContent: 'center', marginLeft: 115}}>
            <img src={arrowRight} alt="Seta" height={10} />
</div> */}
          {/* <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: 20, background:'#F8F9F2', width: 120, height: 120, borderRadius: 16}}>
            <img src={calendarIcon} alt="Energia" height={30} />
            <span style={{fontSize: 8, marginTop: 5}}>no período de</span>
            <span style={{fontSize: 14, fontWeight: 500, marginTop: 10, textAlign: 'center'}}>
              {`${invoice.DT_LEIT_ANT || '-'}`}
              <br />
              {`a ${invoice.DT_LEIT_ATUAL || '-'}`}
            </span>
</div> */}
          {/* <div style={{display: 'flex', position: 'absolute', background: 'var(--secondaryColor)', color: '#fff', borderRadius: '50%', width: 30, height: 30, alignItems: 'center', justifyContent: 'center', marginLeft: 255}}>
            <img src={arrowRight} alt="Seta" height={10} />
</div> */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingTop: 20,
              background: '#F8F9F2',
              width: '100%',
              height: 120,
              borderRadius: 16,
            }}
          >
            <img src={dollarIcon} alt="Energia" height={30} />
            <span style={{ fontSize: 8, marginTop: 5 }}>
              o valor da fatura é
            </span>
            <span style={{ fontSize: 14, fontWeight: 500, marginTop: 10 }}>
              {`R$ ${
                Number(invoice.totalPaymentDue).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                }) || '-'
              }`}
            </span>
          </div>
        </Row>
        <Column style={{ width: '100%', marginTop: 20 }}>
          <div>Detalhes da Fatura</div>
          <Line />
          <Row>
            <span style={{ fontSize: 9, fontWeight: 300, lineHeight: 2 }}>
              Valor original
            </span>
            <span style={{ fontSize: 9, fontWeight: 300, lineHeight: 2 }}>
              {`R$ ${
                Number(invoice.totalPaymentDue).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                }) || '-'
              }`}
            </span>
          </Row>
          {/* <Row>
            <span style={{fontSize: 9, fontWeight: 300, lineHeight: 2}}>Juros</span>
            <span style={{fontSize: 9, fontWeight: 300, lineHeight: 2}}>
              {`R$ ${Number(invoice.C1_JUROS).toLocaleString('pt-BR', {minimumFractionDigits: 2}) || '-'}`}
            </span>
         </Row> */}
          {/*  <Row>
            <span style={{fontSize: 9, fontWeight: 300, lineHeight: 2}}>Multa</span>
            <span style={{fontSize: 9, fontWeight: 300, lineHeight: 2}}>
              {`R$ ${Number(invoice.C1_MULTA).toLocaleString('pt-BR', {minimumFractionDigits: 2}) || '-'}`}
            </span>
        </Row> */}
          {/* <Row>
            <span style={{fontSize: 9, fontWeight: 300, lineHeight: 2}}>Correção</span>
            <span style={{fontSize: 9, fontWeight: 300, lineHeight: 2}}>
              {`R$ ${Number(invoice.C1_CORRECAO).toLocaleString('pt-BR', {minimumFractionDigits: 2}) || '-'}`}
            </span>
         </Row> */}
          <div
            style={{
              display: 'flex',
              opacity: invoice.paymentStatus /* === "ABER" || invoice.paymentStatus === "REAV" */
                ? 1
                : 0,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              style={{ width: '100%', height: 40, marginTop: 20 }}
              ref={inputRef}
              alt="Código de Barras"
            />
            <span style={{ marginTop: 10, fontSize: 12 }}>
              {invoice.barcode}
            </span>
          </div>
        </Column>
      </div>
      <div
        style={{
          marginTop: 15.5,
          alignItems: 'center',
          padding: '5px 25px',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          background: 'var(--secondaryColor)',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {process.env.REACT_APP_PDF_FOOTER ? (
            <span style={{ fontSize: 7, color: '#fff' }}>
              {process.env.REACT_APP_PDF_FOOTER}
            </span>
          ) : null}
          {process.env.REACT_APP_PDF_FOOTER_OUVIDORIA ? (
            <span style={{ fontSize: 7, color: '#fff' }}>
              {process.env.REACT_APP_PDF_FOOTER_OUVIDORIA}
            </span>
          ) : null}
        </div>
        <div
          style={{
            fontSize: 7,
            color: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Pagamento oferecido por
          <img
            src={logoBillWhite}
            alt="Pigz Pay"
            style={{ height: 12, margin: 3 }}
          />
        </div>
      </div>
    </Container>
  )
}

// Register Font
Font.register({
  family: 'Roboto',
  src: font,
})

Font.register({
  family: 'Roboto-Bold',
  src: fontMedium,
})

Font.register({
  family: 'Regular',
  src: regular,
})

// Create styles
const styles = StyleSheet.create({
  page: {
    // backgroundColor: '#FFFFFF',
    backgroundColor: '#F2F9FD',
    opacity: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    margin: '0 auto',
    marginTop: 20,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 15,
  },
  logo: {
    width: 150,
    height: 36,
  },
  logotipo: {
    width: '30%',
    height: 12,
    marginTop: 0,
    // backgroundSize: 'cover',
  },
  left: {
    marginTop: 3,
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.6,
  },
  infovalorigin: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.3,
  },
  title: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 700,
    marginTop: 3,
  },
  right: {
    marginTop: 3,
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    alignItems: 'flex-end',
    color: '#2B2B2B',
    lineHeight: 1.6,
  },
  infovalurosright: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    alignItems: 'flex-end',
    color: '#2B2B2B',
    lineHeight: 1.3,
  },
  Field: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
  Regular: {
    fontSize: 12,
    fontFamily: 'Regular',
  },
  infofat: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: 'Roboto',
    marginLeft: 3,
  },
  infofatval: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'Roboto',
    marginRight: 3,
  },
  comprovante: {
    marginTop: 9,
    fontSize: 24,
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 22,
  },
  client: {
    fontSize: 18,
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
  infoCpf: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: 'Regular',
  },
  infofooter: {
    fontSize: 10,
    fontWeight: 500,
    fontFamily: 'Regular',
    color: '#494949',
  },
  cpf: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 3,
    fontFamily: 'Regular',
    textAlign: 'left',
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 17,
    justifyContent: 'space-between',
  },
  TableInvoices: {
    border: '2.5px solid #2B2B2B',
    borderWidth: 2.5,
    borderColor: '#2B2B2B',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: 9,
    padding: 0,
    marginBottom: 2,
    marginTop: 7,
  },
  container: {
    borderTop: 'none',
  },
  content: {
    // border: '2.5px solid #2B2B2B',
    // padding: 5,
    width: '100%',
  },
  headerinv: {
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  payment: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: 12,
    fontFamily: 'Regular',
    letterSpacing: 0.5,
    // marginTop: 7,
    marginLeft: 7,
  },
  paymentTot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 18,
  },
  infoevento: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
    lineHeight: 1.3,
  },
  event: {
    fontSize: 12,
    color: '#2B2B2B',
    fontFamily: 'Roboto-Bold',
  },
  BlackLine: {
    marginTop: 7,
    marginBottom: 7,
    display: 'flex',
    width: '100%',
    height: '1.5',
    backgroundColor: '#000',
  },
  BlackLinefat: {
    marginTop: 5,
    display: 'flex',
    width: '100%',
    height: '1.5',
    backgroundColor: '#000',
  },
  noBorder: {
    height: 0,
    width: '100%',
  },
  'BlackLinefat:lastChild': {
    height: '0',
    borderTop: 0,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infocredit: {
    fontSize: 12,
    fontFamily: 'Regular',
    color: '#2B2B2B',
  },
})

// Create Document Component
export const ProofPayment = ({ proof }: InvProofDTO): any => {
  const [client, setClient] = useState<Invoice>()

  useEffect(() => {
    const tempClient = localStorage.getItem('invoice')
    if (tempClient) {
      const temp = JSON.parse(tempClient)
      setClient(temp)
      /* const divId = document.getElementById('pdfProof') as HTMLElement
      const printDoc = new jsPDF('portrait', 'px', 'A6')
      printDoc.addFileToVFS(
        '../../assets/fonts/Roboto/Roboto-Medium.ttf',
        'Roboto'
      ) */
      /* if (divId && proof) {
        printDoc.html(divId, {
          callback(pdf) {
            pdf.save(`Comprovante`)
          },
        })
      } */
    }
  }, [proof])

  function transactionDoc(): string {
    const size = proof.transactions.length
    if (size && size !== 1) {
      return proof.transactions[size - 1].doc
    }
    return proof.transactions[0].doc
  }

  function transactionAtk(): string {
    const size = proof.transactions.length
    if (size && size !== 1) {
      return proof.transactions[size - 1].atk
    }
    return proof.transactions[0].atk
  }

  function transactionId(): number {
    const size = proof.transactions.length
    if (size && size !== 1) {
      return proof.transactions[size - 1].id
    }
    return proof.transactions[0].id
  }
  function transactionPan(): string {
    const size = proof.transactions.length
    if (size && size !== 1) {
      return proof.transactions[size - 1].pan
    }
    return proof.transactions[0].pan
  }

  const logo = `${process.env.PUBLIC_URL}/assets/images/logo-black.png`

  function dataFormat(data: string): string {
    const Date = parseISO(data)
    const formattedDate = format(Date, 'EEEEEE, dd/MM/yyyy HH:mm', {
      locale: pt,
    })

    return formattedDate.toUpperCase().replace('  ', '')
  }

  return (
    <Document>
      <Page size={{ width: 377.94, height: 500 }} style={styles.page}>
        <View style={styles.main}>
          <View style={styles.header}>
            <View style={styles.left}>
              <View>
                <Image src={logo} style={styles.logo} />
              </View>
              <View style={styles.title}>
                <Text>{process.env.REACT_APP_HELMET_OG_TITLE}</Text>
              </View>
            </View>
            <View style={styles.right}>
              <View style={styles.Field}>
                <Text>ONLINE</Text>
              </View>

              <View style={styles.Field}>
                <Text>
                  {process.env.REACT_APP_CNPJ
                    ? process.env.REACT_APP_CNPJ.replace(' ', '')
                    : ''}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.comprovante}>
            <Text>Comprovante de Pagamento</Text>
          </View>
          <View style={styles.info}>
            <View style={styles.client}>
              <Text>{`Matrícula ${client?.ref}`}</Text>
            </View>
          </View>

          <View style={styles.cpf}>
            <Text>{`CPF/CNPJ: ${transactionDoc()}`}</Text>
          </View>

          <View style={styles.tags}>
            <View style={styles.infofat}>
              <Text>FATURA</Text>
            </View>
            <View style={styles.infofatval}>
              <Text>VALOR</Text>
            </View>
          </View>
          <View style={styles.TableInvoices}>
            {proof?.invoices.map((inv, i) => {
              return (
                <View key={inv.id} style={styles.container}>
                  <View style={styles.content}>
                    <View style={styles.headerinv}>
                      <View style={styles.infovalorigin}>
                        <View style={styles.client}>
                          <Text>{inv.contentRefDate}</Text>
                        </View>
                      </View>
                      <View style={styles.infovalurosright}>
                        <View style={styles.client}>
                          <Text>
                            {`R$ ${inv.total.toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                            })}`}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={
                        i === proof?.invoices.length - 1
                          ? styles.noBorder
                          : styles.BlackLinefat
                      }
                    />
                  </View>
                </View>
              )
            })}
          </View>
          <View style={styles.tags}>
            <View style={styles.infofat}>
              <Text>CRÉDITO</Text>
            </View>
          </View>
          <View style={styles.payment}>
            <Text>{`CÓD. AUT ${transactionAtk()}`}</Text>
            <Text>{`ID ${transactionId()}`}</Text>
            {
              // <Text>{`ID ${proof?.transacoes[0].id}`}</Text>
            }
            <Text>{`PAN ${transactionPan()}`}</Text>
          </View>
          <View style={styles.paymentTot}>
            <View style={styles.client}>
              <Text>Valor Total</Text>
            </View>
            <View style={styles.client}>
              <Text>
                {`R$ ${Number(`${proof?.total}`).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}`}

              </Text>
            </View>
          </View>
          {process.env.REACT_APP_EVENT_INFO ? (
            <View style={styles.infoevento}>
              <View style={styles.event}>
                <Text>{process.env.REACT_APP_EVENT_INFO}</Text>
              </View>
              {process.env.REACT_APP_EVENT_INFO_SECOND_LINE ? (
                <View style={styles.event}>
                  <Text>{process.env.REACT_APP_EVENT_INFO_SECOND_LINE}</Text>
                </View>
              ) : null}
            </View>
          ) : null}
          <View style={styles.BlackLine} />
          <View style={styles.footer}>
            <View style={styles.infofooter}>
              <Text>Arrecadação Multicanal - </Text>
            </View>
            <View style={styles.logotipo}>
              <Image
                src={`${process.env.PUBLIC_URL}/images/logo-bill.png`}
                style={styles.logotipo}
              />
            </View>
            <View style={styles.infofooter}>
              <Text>© Orange Labs 2021</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )

  return (
    <Container id="pdfProof" style={{ width: 224, margin: 'auto' }}>
      <div
        style={{
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Row
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Column>
            <img alt="Logo" height={20} width={80} src={logo} />
            <span style={{ fontWeight: 500, fontSize: 8 }}>CAER S/A</span>
          </Column>
          <Column style={{ alignItems: 'flex-end' }}>
            <span style={{ fontSize: 7, lineHeight: 1.5, fontWeight: 500 }}>
              MATRIZ
            </span>
            <span style={{ fontSize: 7, lineHeight: 1.5, fontWeight: 500 }}>
              {`CNPJ: ${process.env.REACT_APP_CNPJ}`}
            </span>
          </Column>
        </Row>
        <span style={{ fontSize: 10, fontWeight: 500, margin: 8 }}>
          Comprovante de Pagamento
        </span>
        <Row
          style={{
            width: '100%',
            justifyContent: 'space-between',
            marginTop: 6,
          }}
        >
          <span style={{ fontSize: 10, fontWeight: 600 }}>
            {`Matrícula ${client?.ref}`}
          </span>
        </Row>
        <Row>
          <span style={{ fontSize: 7, fontWeight: 300 }}>
            {`CPF/CNPJ: ${transactionDoc()}`}
          </span>
        </Row>
        <Row
          style={{
            justifyContent: 'space-between',
            padding: 4,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <span style={{ fontSize: 9, fontWeight: 500 }}>FATURA</span>
          <span style={{ fontSize: 9, fontWeight: 500 }}>VALOR</span>
        </Row>
        <TableInvoices>
          {proof?.invoices.map((inv) => {
            return (
              <div key={inv.id}>
                <div>
                  <div>
                    <span>
                      {inv.contentRef} 
                      {' '}
                      {inv.contentRefDate}
                      {' '}
                    </span>
                    <span>
                      {`VALOR R$ ${inv.total.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                      })}`}
                    </span>
                  </div>
                  {/* <div>
                    <span>{`R$ ${inv.total.toLocaleString('pt-BR', {minimumFractionDigits: 2})}`}</span>
                    <span>{`J+M+C R$ R$ ${(inv.correcao + inv.juros + inv.multa).toLocaleString('pt-BR', {minimumFractionDigits: 2})}`}</span>
                 </div> */}
                </div>
              </div>
            )
          })}
        </TableInvoices>
        {/* <Row style={{width: '100%', justifyContent: 'space-between', marginTop: 4, marginBottom: 4}}>
          <span style={{fontSize: 9, fontWeight: 500}}>CRÉDITO</span>
          <span style={{fontSize: 9, fontWeight: 500}}>{`${proof?.transactions[0].installments} SEM JUROS DE R$ ${proof?.total}`}</span>
        </Row> */}
        <Column style={{ width: '100%', justifyContent: 'flex-start' }}>
          <span style={{ fontSize: 7 }}>{`CÓD. AUT ${transactionAtk()}`}</span>
          <span style={{ fontSize: 7 }}>{`ID ${transactionId()}`}</span>
          <span style={{ fontSize: 7 }}>{`PAN ${transactionPan()}`}</span>
        </Column>
        <Row style={{ marginTop: 7 }}>
          <span style={{ fontSize: 12, fontWeight: 600 }}>Valor Total</span>
          <span style={{ fontSize: 12, fontWeight: 600 }}>
            {`R$ ${proof?.total}`}
          </span>
        </Row>
        {process.env.REACT_APP_EVENT_INFO ? (
          <Column style={{ alignItems: 'center', marginTop: 7 }}>
            <span style={{ fontSize: 7, wordSpacing: 2 }}>
              {process.env.REACT_APP_EVENT_INFO}
            </span>
            <span style={{ fontSize: 7, wordSpacing: 2 }}>
              {process.env.REACT_APP_EVENT_INFO_SECOND_LINE}
            </span>
          </Column>
        ) : null}
        <BlackLine />
        <Row>
          <img src={logoBill} alt="PigzPay" height={12} />
          <span style={{ fontSize: 8 }}>Soluções de Pagamentos</span>
        </Row>
      </div>
    </Container>
  )
}
