import React from 'react';

import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import iconError from '../../assets/icons/error.svg';
import logo from '../../assets/icons/logo.svg';

const GenericError: React.FC = () => {

  const history = useHistory();
  return (
    <Container>
      <div>
        <img src={logo} alt="Logo icone" />
      </div>
      <span>Oops, parece que ocorreu um erro!</span>
      <img src={iconError} alt="Icone de Erro" />
      <button type="button" onClick={()=> {history.push('/')}}>Ir para o início</button>
    </Container>
  );
}

export default GenericError;
