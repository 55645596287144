import styled from 'styled-components'

export const Container = styled.div`
  background-color: #fff;
  // font-family: 'Arial', 'sans-serif';
`

export const Span = styled.span`
  font-size: 10px;
  color: var(--secondaryColor);
`

export const SpanInfo = styled.span`
  font-size: 13px;
`

export const TableInvoices = styled.div`
  border: 1px solid #000;
  //font-family: 'Arial', 'sans-serif';
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  width: 100%;
  border-radius: 9px;
  padding: 0px;
  margin-bottom: 5px;
  div:first-child {
    border-top: none;
  }
  > div {
    border-top: 2px solid #000;
    padding: 5px;
    width: 100%;
    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span:first-child {
          font-size: 12px;
          font-weight: 600;
        }
        span {
          font-size: 6px;
        }
      }
    }
  }
`

export const Line = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
  display: flex;
  width: 100%;
  height: 0.5px;
  background-color: var(--secondaryColor);
`

export const BlackLine = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #000;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
