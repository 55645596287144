import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const BoxInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border-style: solid;
  border-width: 0.5px;input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  border-color: #707070;
  padding-left: 10px;
  padding-right: 10px;
  input{
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Roboto';
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const Label = styled.label`
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: 'Roboto';
  color: #494949;
`;

export const Span = styled.span`
  font-size: 14px;
  margin-left: 10px;
  color:red;
  height: 10px;
`;
