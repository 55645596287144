import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { PDFDownloadLink } from "@react-pdf/renderer";

import infoGreen from './information.svg'

import { ProofPayment } from '../../../../components/PDF'
import { PayDTO, PaymentDTO } from '../../../../utils/models'
import {
  Container,
  Box,
  Title,
  Top,
  Bottom,
  Button,
  TableInvoices,
} from './styles'

interface Invoice {
  data: PaymentDTO
}

const Success: React.FC = () => {
  const { state } = useLocation<Invoice>()
  const history = useHistory()
  const [printProofPayment, setPrintProofPayment] = useState<PayDTO>()
  const [hasPrint, setHasPrint] = useState(false)
  const [total, setTotal] = useState(0)

  function printProof(): void {
    // console.log('printer')
    setHasPrint(true)
    setTimeout(() => {
      // setHasPrint(false)
    }, 3000)
  }

  useEffect(() => {
    const tempPayment = localStorage.getItem('statusPaymentResume')
    // console.log(tempPayment)
    if (tempPayment) {
      setPrintProofPayment(JSON.parse(tempPayment))
      setTotal(JSON.parse(tempPayment).total)
    } else {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <Container>
      <Box>
        <Title>Pagamento Recebido</Title>
        <Top>
          <div>
            <span>Fatura</span>
            <span>Valor</span>
          </div>
          <TableInvoices>
            {printProofPayment?.invoices?.map((invoice) => {
              return (
                <div key={invoice.id}>
                  <div>
                    <span>{invoice.contentRef}</span>
                    <span>
                      {`R$ ${invoice.total.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                      })}`}
                    </span>
                  </div>
                </div>
              )
            })}
          </TableInvoices>
          <TableInvoices>
            <div>
              <div>
                <span>Total</span>
                <span>
                  {`R$ ${total.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                  })}`}
                </span>
              </div>
            </div>
          </TableInvoices>
        </Top>
        <Bottom>
          <img src={infoGreen} alt="InfoIcon" />
          <span>
            Seu pagamento foi recebido e está sendo processado. Enviaremos um
            e-mail com o comprovante após a confirmação. Caso haja necessidade
            de verificações extras, a CAER entrará em contato.
          </span>
        </Bottom>
        <Button
          style={{
            filter:
              hasPrint && printProofPayment ? 'grayscale(1)' : 'grayscale(0)',
            visibility: !hasPrint ? 'visible' : 'hidden',
          }}
          className="proofButton"
          onClick={printProof}
        >
          VER COMPROVANTE
        </Button>
        <div>
          {hasPrint && printProofPayment && (
          <PDFDownloadLink
            style={{ marginBottom: 20, textDecoration: 'none', color: ' #fff',display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 48, border: 'none', fontSize: 16, fontFamily: 'Roboto', fontWeight: 500, backgroundColor: 'var(--primaryColor)'}}
            document={<ProofPayment proof={printProofPayment} />}
            fileName='comprovante.pdf'
          >
            {({ blob, url, loading, error }) => loading ? (
              'CARREGANDO'
            ) : (
              'DOWNLOAD DO COMPROVANTE'
            )}
            {// <ProofPayment proof={printProofPayment} />
            }
          </PDFDownloadLink>
        )}
        </div>
        <Button
          className="homeButton"
          onClick={() => {
            history.push('/login')
          }}
        >
          IR PARA A TELA INICIAL
        </Button>
      </Box>
      { /* <div>
        {hasPrint && printProofPayment && (
          <PDFDownloadLink
            style={{ marginBottom: 20, textDecoration: 'none', color: ' #fff',display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 48, border: 'none', fontSize: 16, fontFamily: 'Roboto', fontWeight: 500, backgroundColor: 'var(--primaryColor)'}}
            document={<ProofPayment proof={printProofPayment} />}
            fileName='comprovante.pdf'
          >
            {({ blob, url, loading, error }) => loading ? (
              'CARREGANDO'
            ) : (
              'DOWNLOAD DO COMPROVANTE'
            )}
            {// <ProofPayment proof={printProofPayment} />
            }
          </PDFDownloadLink>
        )}
      </div> */ }
      <div style={{ width: 50, height: 10 }} />
    </Container>
  )
}
export default Success
