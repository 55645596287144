import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height:  -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0, 0.5);
  position: absolute;
  z-index: 30;
`;


export const Modal = styled.div`
  width: 584px;
  border-radius: 8px;
  padding: 40px;
  background-color: #fff;
  opacity: 1;
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto;
  align-items: center;
  img {
    width: 250px;
    margin: 0;
  }
  h2 {
    width: 100%;
    font-size: 36px;
    color: #2b2b2b;
  }
  >div {
    padding-left: 40px;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  p {
    font-size: 16px;
    line-height: 1.6rem;
    color: #2B2B2B;
    font-weight: 300;
  }
  button {
    width: 214px;
    height: 40px;
    border: none;
    color: #fff;
    font-size: 20px;
    border-radius: 8px;
    background-color: var(--primaryColor);
  }

  @media only screen and (max-width: 768px){
    width: 90%;
    border-radius: 8px;
    padding: 40px;
    background-color: #fff;
    opacity: 1;
    display: flex;
    gap: 0px;
    flex-direction: column;
    align-items: flex-end;
    img {
      width: 160px;
      position: fixed;
      right: 12%;
    }
    >div {
      margin-top: 80px;
      height: 250px;
      padding-left: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border:none;
    }
    h2 {
      font-size: 30px;
      color: #2b2b2b;
    }
    p {
      margin-top: 1.5rem;
      font-size: 20px;
      line-height: 1.6rem;
      color: #494949;
    }
    button {
      margin-top: 3rem;
      width: 100%;
      min-height: 40px;
      border: none;
      color: #fff;
      font-size: 20px;
      border-radius: 8px;
      background-color: var(--primaryColor);
    }
  }

`;
