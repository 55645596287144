/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import cookie from 'react-cookies';
import {isAuthenticated} from "../utils/auth";

const getCookie = cookie.load('OrangeLabs');

const PrivateRoute = ({component, ...rest}: any):any => {

  function getData():boolean{
    const token = localStorage.getItem('token');
    if(getCookie && token === null){
      return true
    }
    return false
  }

  const routeComponent = (props: any):any => (
    isAuthenticated()
          ? React.createElement(component, props)
          : getData()
            ? <Redirect to={{pathname: '/redirect'}} />
            : <Redirect to={{pathname: '/login'}} />
  );
  return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
