import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
`;
export const BoxInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border-style: solid;
  border-width: 0.5px;
  border-color: #707070;
  .react-select-container{
    width: 100%;
    border: none;
  }
  .react-select__control{
    border: none;
  }
  .react-select-error-container{
    width: 100%;
    border-radius: 8px;
    border: 1px solid red;
  }
  .react-select-error__control{
    border: none;
  }
  @media only screen and (max-width: 768px){
    .selectWeb{

      display: none;
      overflow: hidden;
      opacity: 0;
    }
  }
`;

export const SelectMobile = styled.select`
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 8px;
  border-style: solid;
  border-width: 0.5px;
  border-color: #707070;
  background-color: #fff;
`;

export const Label = styled.label`
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 5px;
  font-family: 'Roboto';
  color: #494949;
`;
