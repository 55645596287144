import { createStore } from 'redux';
import { Invoice, newState, Refs, StateInitial } from '../utils/models';


const INITIAL_STATE:StateInitial = {
  DATA_CLIENT: {
    CEP_LOGRADOURO: '',
    COMPLEMENTO:  '',
    FLAG_CLIENTE_ATUAL:  '',
    IDENT_CLIENTE_FATURA:  '',
    IDENT_CLIENTE_UC_ATUAL:  '',
    IDENT_UC:  '',
    LOGR_TIPO_NOME:  '',
    NOME_BAIRRO:  '',
    NOME_CLIENTE:  '',
    NOME_LOCAL:  '',
    NOME_MUNICIPIO:  '',
    NUMERO_DOCTO_1:  '',
    NUMERO_IMOVEL:  '',
    TIPO_DOCTO:  '',
    LATITUDE: '',
    LONGITUDE: ''
  },
  NOME_CLIENTE:  '',
  loading: true,
  SHOW_MODAL: false,
  CONNECTION: true,
  SEARCH_DB: false,
};


function reducer(state = INITIAL_STATE, action: any): any {
    switch (action.type) {
      case 'CONNECTION_CHANGED':
        return {...state, CONNECTION: action.connection};
      case 'STOP_LOADING':
        return {...state, loading: action.show};
      case 'SET_NAME':
        return {...state, NOME_CLIENTE: action.name};
      case 'SET_UC':
        return {...state, DATA_CLIENT: action.data};
      case "RESET":
        return INITIAL_STATE;
      case 'SET_VISIBILITY_MODAL':
        return {...state, SHOW_MODAL: action.showModal};
      case 'SET_SEARCH_DB_LOADING':
        return {...state, SEARCH_DB: action.loadingSearchBd};
      default:
        return state;
    }
}



const NEW_STATE: newState={
  NOME_CLIENT:''
  ,MATRICULA:'',
  ENDERECO:'',
  loading: true,
  SHOW_MODAL: false,
  CONNECTION: true,
  SEARCH_DB: false,

  DATA_INVOICE:[{} as Invoice],
  DATA_REF: [{} as Refs]

}

function newReducer(state = NEW_STATE, action: any): any {
  switch (action.type){
    case 'SET_NAME':
      return {...state,NOME_CLIENT: action.name}
    case 'SET_MATRICULA':
      return {...state,MATRICULA:action.matricula}
    case 'SET_ENDERECO':
      return {...state,ENDERECO:action.endereco}
    case 'SET_INVOICES':
      return {...state,DATA_INVOICE: action.invoices}
    case 'SET_REFS':
      return {...state,DATA_REF:action.refs}

    case "RESET":
      return NEW_STATE;

    default:
      return state
  }

}

const store = createStore(reducer);

const newStore = createStore(newReducer)

export default newStore;
