/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable radix */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {faArrowLeft, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import informationIcon from '../../assets/icons/information.svg'
import loadingIcon from '../../assets/icons/loading.svg';
import Select from '../../components/Select'
import {
  Box,
  Container,
  Header,
  Title,
  Body,
  Left,
  Right,
  BtnBack,
  ButtonPay,
  ScrollInvoice,
  InvoiceDetails,
  TitleInvoiceDetails,
  DescriptionInvoice
} from './styles';
import InputMask, { InputMaskTel } from '../../components/InputMask'
import Input from '../../components/Input';
import { mundiPagg,newApi } from '../../services/api';
import {  MundiPaggDTO, MundiReturnDTO, PayDTO, paymentInvoice, Invoice } from '../../utils/models';
import Footer from '../../components/Footer';
import Modal from './components/Modal';
import { isAuthenticated } from '../../utils/auth';
// import replaceSpecialChars from '../../functions/replaceSpecialChars'


interface LocationStateDTO{
  data: PayDTO;
}

interface Errors {
  [key: string]: string;
}

const Payment: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [userInfo, setUserInfo] = useState<Invoice>();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const location = useLocation<LocationStateDTO>();
  const [paymentResume, setPaymentResume] = useState<PayDTO>();
  const [openDetail, setOpenDetail] = useState<number>();
  const [total, setTotal] = useState<number>(0);
  const [listInvoicePay, setList] = useState<Array<paymentInvoice>>();
  const [invoideId, setInvoiceId] = useState<Array<paymentInvoice>>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingErase, setLoadingErase] = useState<boolean>(false);
  const [optionsSelect, setOptionsSelect] = useState<Array<any>>();
  const [showModalErrorMundi, setShowModalErrorMundi] = useState(false);

  useEffect(()=>{
    if(!isAuthenticated()){
      history.push('/login')
    }
  },[history]);

  useEffect(()=>{
    const userTemp =  localStorage.getItem('invoice');
    if(userTemp){
      setUserInfo(JSON.parse(userTemp));
    }
  },[]);

  useEffect(() => {
    if(location.state && paymentResume === undefined){
      localStorage.setItem('paymentResume', JSON.stringify(location.state.data))
      setPaymentResume(location.state.data);
    } else {
      history.push('/');
    }
  }, [location]);

  useEffect(()=>{
    if(paymentResume?.invoices.length === 1){
      setOpenDetail(location.state.data.invoices[0].id)
    }
    if(paymentResume)
    setTotal(parseFloat(paymentResume?.total));
    const temp = paymentResume?.invoices;
    setInvoiceId(temp)
    setList(paymentResume?.invoices);
    const tempOption = [];
    if(paymentResume?.invoices){
      for(let i=1; paymentResume?.invoices.length > 6 ? i <= 6 :i <= paymentResume?.invoices.length; i+=1){
        tempOption.push({ value: i, label: `${i}x R$ ${(parseFloat(paymentResume?.total)/i).toLocaleString('pt-BR', {minimumFractionDigits: 2})} (sem juros)` });
      }
    }
    setOptionsSelect(tempOption);
  },[paymentResume]);
/*
  async function removeInvoice(idInvoice: number):Promise<void>{
    setLoadingErase(true)
    const token = sessionStorage.getItem('token');
    try {
      if(paymentResume?.invoices.length === 1) {
        throw new Error("");
      }
      const {data} = await api.patch(`pagamento/${paymentResume?.id}/fatura`, {
        faturas: [idInvoice]
      },{
        headers: {
          Authorization: `Basic ${token}`,
          "X-Forwarded-Host": window.location.hostname||process.env.REACT_APP_HOST
        }
      });
      setPaymentResume(data);
      setLoadingErase(false);
    } catch (error) {
      setLoadingErase(false);
      history.push('/');
    }
  }
*/

  function consultDataInput(): void{
    if(formRef.current){
      generateTokenMundi({
        number: formRef.current.getData().cardNumber.replace(/\s/g, ''),
        holder_name: String(formRef.current.getData().name).toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
        exp_month: parseInt(formRef.current.getData().valid?.substr(0,2)),
        exp_year: parseInt(formRef.current.getData().valid?.substr(3,4)),
        cvv: formRef.current.getData().cvv,
        label: ''
      });
    }
  }

  async function generateTokenMundi(card: MundiPaggDTO):Promise<void> {
    try {
      setLoadingPayment(true);
      const {data} = await mundiPagg.post(`/tokens?appId=${process.env.REACT_APP_API_MUNDI}`, {
        type: "card",
        card
      });
      pay(data);
    } catch (err: any) {
      setLoadingPayment(false);
      // console.log(err)
      if(err.status === 500){
        setShowModalErrorMundi(true);
      }else if(formRef.current){
        formRef.current.setErrors({
          'cardNumber': 'Dados do cartão inválidos',
          'cvv': 'Dados do cartão inválidos',
          'valid': 'Dados do cartão inválidos',
          'name': 'Dados do cartão inválidos',
        });
      }
    }
  }

  async function pay(dataMuni: MundiReturnDTO): Promise<void> {

    
    

    
    const token = sessionStorage.getItem('token'); 
    if(formRef.current && token){
     /* console.log({
        transactions: [
          {
            name:replaceSpecialChars(formRef.current.getData().name),
            doc: String(formRef.current.getData().document).replace(/[^\d]+/g,''),
            email:  formRef.current.getData().email,
            holderName: formRef.current.getData().name,
            phone: formRef.current.getData().phone,
            token: dataMuni.id,
            invoice: location.state.data.total,
            installments: formRef.current.getData().plots

           // cliente: location.state.data.cliente,
           // tipo: 1,
          }
        ]
      }); */

      

      try {
        const {data} = await newApi.post(`/mobile/payment/${location.state.data.id}/process`,{
          transactions: [
            {
              name:formRef.current.getData().name,
              doc: String(formRef.current.getData().document).replace(/[^\d]+/g,''),
              email: formRef.current.getData().email,
              holderName: formRef.current.getData().name,
              phone: formRef.current.getData().phone,
              token: dataMuni.id,
              invoice: location.state.data.total,
              installments: formRef.current.getData().plots

             // cliente: location.state.data.cliente,
             // tipo: 1,
            }
          ]
        }, {
          headers: {
            Authorization: `Basic ${token}`,
            "X-Forwarded-Host": window.location.hostname||process.env.REACT_APP_HOST
          }
        })
        setLoadingPayment(false);
        // console.log('paymentData: ',data)
        if([4,5].includes(data.status)){
          localStorage.setItem('statusPaymentResume', JSON.stringify(data));
          history.push('/payment/success');
        }else{
          localStorage.setItem('paymentResume', JSON.stringify(data));
          history.push('/payment/error');
        }
      } catch (error) {
        history.push('/payment/error');
        setLoadingPayment(false);
      }
    }

  }

  async function handleSubmit():Promise<void> {
    if(formRef.current){
    try {
      const schema = Yup.object().shape({
        cardNumber: Yup.string().min(15, "Formato inválido").required("O número do cartão é obrigatório"),
        valid: Yup.string().min(5).max(5).required("A validade é obrigatória"),
        name: Yup.string().min(4, "Nome inválido").required("O nome é obrigatório"),
        cvv: Yup.string().min(3, "Formato Inválido").max(4, "Formato Inválido").required("O código verificador é obrigatório"),
        phone: Yup.string().min(11, "Formato Inválido").required("O numero de telefone é obrigatório"),
        email: Yup.string().email("Formato de email inválido").required("O email é obrigatório"),
        plots: Yup.number().required("A quantidade de parcelas é obrigatória")
      });
      await schema.validate(formRef.current.getData(),{
        abortEarly: false
      });
      consultDataInput();
    } catch (err) {
      const validationErrors: Errors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error:any) => {
            validationErrors[error.path] = error.message
          });
          if(formRef.current)
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  }

  async function checkCardNumber():Promise<void> {
    if(formRef.current){
      try {
        const schema = Yup.object().shape({
          cardNumber: Yup.string().min(15, "Formato inválido").required("O número do cartão é obrigatório"),
        });
        await schema.validate(formRef.current.getData(),{
          abortEarly: false
        });
      } catch (err) {
        const validationErrors: Errors = {};
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach((error:any) => {
              validationErrors[error.path] = error.message
            });
            if(formRef.current)
            formRef.current.setErrors(validationErrors);
          }
        }
      }
  }

  async function checkValid():Promise<void> {
    if(formRef.current){
      try {
        const schema = Yup.object().shape({
          valid: Yup.string().min(5).max(5).required("A validade é obrigatória"),
        });
        await schema.validate(formRef.current.getData(),{
          abortEarly: false
        });
      } catch (err) {
        const validationErrors: Errors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error:any) => {
            validationErrors[error.path] = error.message
          });
          if(formRef.current)
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  }

  async function checkName():Promise<void> {
    if(formRef.current){
      try {
        const schema = Yup.object().shape({
          name: Yup.string().min(6, "Nome inválido").required("O nome é obrigatório"),
        });
        await schema.validate(formRef.current.getData(),{
          abortEarly: false
        });
      } catch (err) {
        const validationErrors: Errors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error:any) => {
            validationErrors[error.path] = error.message
          });
          if(formRef.current)
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  }

  async function checkCvv():Promise<void> {
    if(formRef.current){
      try {
        const schema = Yup.object().shape({
          cvv: Yup.string().min(3, "Tamanho inválido").max(4, "Tamanho inválido").required("O código verificador é obrigatório"),
        });
        await schema.validate(formRef.current.getData(),{
          abortEarly: false
        });
      } catch (err) {
        const validationErrors: Errors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error:any) => {
            validationErrors[error.path] = error.message
          });
          if(formRef.current)
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  }

  async function checkPhoneNumber():Promise<void> {
    if(formRef.current){
      try {
        const schema = Yup.object().shape({
          phone: Yup.string().min(11, "Formato Inválido").required("O numero de telefone é obrigatório"),
        });
        await schema.validate(formRef.current.getData(),{
          abortEarly: false
        });
      } catch (err) {
        const validationErrors: Errors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error:any) => {
            validationErrors[error.path] = error.message
          });
          if(formRef.current)
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  }

  async function checkEmail():Promise<void> {
    if(formRef.current){
      try {
        const schema = Yup.object().shape({
          email: Yup.string().email("Formato de email inválido").required("O email é obrigatório"),
        });
        await schema.validate(formRef.current.getData(),{
          abortEarly: false
        });
      } catch (err) {
        const validationErrors: Errors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error:any) => {
            validationErrors[error.path] = error.message
          });
          if(formRef.current)
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  }

  async function checkPlots():Promise<void> {
    if(formRef.current){
      try {
        const schema = Yup.object().shape({
          plots: Yup.number().required("A quantidade de parcelas é obrigatória")
        });
        await schema.validate(formRef.current.getData(),{
          abortEarly: false
        });
      } catch (err) {
        const validationErrors: Errors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error:any) => {
            validationErrors[error.path] = error.message
          });
          if(formRef.current)
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  }

  async function checkDocNumber():Promise<void> {
    if(formRef.current){
      try {
        const schema = Yup.object().shape({
          document: Yup.string().min(14, "Formato inválido").required("O documento é obrigatório")
        });
        await schema.validate(formRef.current.getData(),{
          abortEarly: false
        });
      } catch (err) {
        const validationErrors: Errors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error:any) => {
            validationErrors[error.path] = error.message
          });
          if(formRef.current)
          formRef.current.setErrors(validationErrors);
        }
      }
    }
  }

  function cutName(name: string | undefined):string{
    if(name === undefined){
      return "NOME"
    }
    if(name.length > 22){
      return `${name.substring(0,22)}...`;
    }
    return name;
  }
  function transformDate(d: string):string{
    const dSplit = d.split('-')

    return `${dSplit[2]}/${dSplit[1]}/${dSplit[0]}`




    return ''

  }


  function backButton():void{
    history.push({
      pathname: "/",
      state: {
        data: invoideId
      }
    });
  }

  return (
    <Container>
      <Modal showModalErrorMundi={showModalErrorMundi} />
      <Box>
        <Header>
          <Title>Pagar com Cartão de Crédito</Title>
          <BtnBack type="button" onClick={backButton}>
            <FontAwesomeIcon icon={faArrowLeft} />
            Voltar
          </BtnBack>
        </Header>
        <Body>
          <Left>
            <div>
              <span>
                Dados do Pagamento
              </span>
            </div>
            <Form style={{padding: '0 15px', margin: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}} ref={formRef} onSubmit={handleSubmit} autoSave="">
              <div style={{display: 'grid', gap: 15, gridTemplateColumns: '3fr 1fr'}}>
                <InputMask type="tel" onBlurCapture={checkCardNumber} name="cardNumber" label="Numero do Cartão de Crédito" mask="9999 9999 9999 9999" />
                <InputMask type="tel" onBlurCapture={checkValid} name="valid" label="Validade" maxLength={5} mask="99/99" />
              </div>
              <div style={{display: 'grid', gap: 15, gridTemplateColumns: '3fr 1fr'}}>
                <Input onBlurCapture={checkName} name="name" label="Nome do Titular" />
                <InputMask type="tel" onBlurCapture={checkCvv} name="cvv" label="CVV" mask="9999" />
              </div>
              <div style={{display: 'grid', gap: 15, gridTemplateColumns: '2fr 2fr'}}>
                <InputMask type="tel" onBlurCapture={checkDocNumber} name="document" label="CPF" mask="999.999.999-99" />
                <InputMaskTel type="tel" onBlurCapture={checkPhoneNumber} name="phone" label="Telefone" mask="(99) 99999-9999" />
              </div>
              <Input onBlurCapture={checkEmail} name="email" label="Email" />
              <Select options={optionsSelect} onBlurCapture={checkPlots} name="plots" label="Quantidade de parcelas" />
            </Form>
          </Left>
          <Right>
            <div>
              <div>
                <span style={{marginBottom: 12}}>
                  Resumo do Pagamento
                </span>
              </div>
              <div style={{padding: '10px 15px 0 15px'}}>
                <span style={{fontSize: 13, fontFamily: 'Roboto', fontWeight: 300, color: '#494949'}}>Cliente / Matrícula</span>
                <h4 style={{fontSize: 18, fontFamily: 'Roboto', fontWeight: 500, color: '#494949'}}>{`${cutName(userInfo?.customer || "...")} / ${userInfo?.ref || ''}`}</h4>
              </div>
              <span style={{fontSize: 13, fontFamily: 'Roboto', fontWeight: 300, color: '#494949', marginTop: 10, marginBottom: 10}}>{`Faturas selecionadas ${listInvoicePay?.length}`}</span>
              <ScrollInvoice>
                <div>
                  {listInvoicePay?.map((invoice)=>(
                    <InvoiceDetails key={invoice.id} style={{transition: '0.4s', height: openDetail === invoice.id ? 95 /** 143 */ : 40}}>
                      <button onClick={()=>{if(openDetail === invoice.id){setOpenDetail(undefined)}else{setOpenDetail(invoice.id)}}} type='button' style={{border: 'none', display: 'flex', width: '100%', justifyContent: 'space-between', flexDirection: 'row', background: 'transparent'}}>
                        <TitleInvoiceDetails>
                          {`Fatura ${invoice.contentRefDate}` /** contentRef */}
                        </TitleInvoiceDetails>
                        <div>
                          <TitleInvoiceDetails>
                            {`R$ ${invoice.total.toLocaleString('pt-BR', { minimumFractionDigits: 2})}`}
                          </TitleInvoiceDetails>
                          <button type='button' style={{background: 'transparent', marginLeft: 10, marginRight: 8, border: 'none'}}>
                            <FontAwesomeIcon icon={faChevronDown} style={{transition: '0.4s', transform: openDetail === invoice.id ? 'rotate(180deg)' : 'rotate(0deg)'}} color="#7fa323" />
                          </button>
                        </div>
                      </button>
                      <div style={{transition: '0.2s', overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        <div style={{paddingBottom: 10}}>
                          <DescriptionInvoice>{`Venc. ${invoice.paymentDueDate ? transformDate(invoice.paymentDueDate) : ''}` /** paymentDueDate */ }</DescriptionInvoice>
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <DescriptionInvoice>Valor original</DescriptionInvoice>
                            <DescriptionInvoice>{`R$ ${invoice.total.toLocaleString('pt-BR',{minimumFractionDigits: 2})}`}</DescriptionInvoice>
                          </div>
                          {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <span style={{fontSize: 13, fontFamily: 'Roboto', color: '#494949', lineHeight: 1.5}}>Juros + Multa + Correção</span>
                            <span style={{fontSize: 13, fontFamily: 'Roboto', color: '#494949', fontWeight: 500}}>{
                             // `R$ ${(invoice.correcao+invoice.juros+invoice.multa).toLocaleString('pt-BR', {minimumFractionDigits: 2})}` 
                            `R$ ${(invoice.total).toLocaleString('pt-BR', {minimumFractionDigits: 2})}`}</span>
                            </div> */}
                        </div>
                        {/* <button onClick={()=>{removeInvoice(invoice.id)}} type='button' style={{background: '#fff', width: '100%', height: 35, borderRadius: 8, border: `1px solid ${loadingErase ? '#494949' : '#D93B3C'} ` , display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <FontAwesomeIcon icon={faTrashAlt} style={{transition: '0.4s'}} color={loadingErase ? '#494949' : '#D93B3C'} />
                          <span style={{color: loadingErase ? '#494949' : '#D93B3C' , fontSize: 13, fontFamily: 'Roboto', fontWeight: 500, marginLeft: 10}}>
                            {loadingErase ? 'REMOVENDO' : 'REMOVER ESTA FATURA'}
                          </span>
                          </button> */}
                      </div>
                    </InvoiceDetails>
                  ))}
                </div>
              </ScrollInvoice>
              <ButtonPay type="button" onClick={handleSubmit} disabled={loadingPayment} style={{ cursor: !loadingPayment ? 'pointer' : 'initial'}}>
                {loadingPayment ? <img src={loadingIcon} alt="Loading" style={{height: 50}} /> : `Pagar R$ ${total.toLocaleString('pt-BR', { minimumFractionDigits: 2})}`}
              </ButtonPay>
            </div>            
          </Right>
        </Body>
        <div id="mobile">
          <Footer />
        </div>
      </Box>
      <div id="web" style={{width: '100%'}}>
        <Footer />
      </div>
    </Container>
  );
}

export default connect((state: any) => ({modules: state}))(Payment);
