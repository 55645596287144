/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { ReCaptcha } from 'react-recaptcha-v3'
import { nanoid } from 'nanoid'
import { AxiosResponse } from 'axios'
import whiteLogo from '../../assets/icons/logo-amazonas-energia-branco.svg'
import whiteLogoHorizontal from '../../assets/icons/logo-amazonas-energia-horizontal.svg'
import loadingIcon from '../../assets/icons/loading.svg'
import loginImage from '../../assets/icons/login-image.svg'
import imageSmartPhone from '../../assets/images/smartphone-ame-mockup.png'
import { Invoice, Refs } from '../../utils/models'
import LogoBillGray from '../../assets/images/logo-bill-gray.svg'

import {
  Container,
  Box,
  BoxLeft,
  BoxRight,
  TextFoot,
  Label,
  Button,
  BoxTextAndCard,
  BoxTwoImages,
 FormButton  

} from './style'
import { newApi } from '../../services/api'
import Input from './components/Input'
import Recaptcha from './components/Recaptcha'

interface Errors {
  [key: string]: string
}

const TWO_MINUTES = 2 * 60000

const UpdatedLogin: React.FC = ({ modules, dispatch }: any) => {
  const [loading, setLoading] = useState(false)
  const [loadingCaptcha, setLoadingCaptcha] = useState(true)
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const [googleRecaptcha, setGoogleRecaptcha] = useState<string | undefined>(
    undefined
  )
  const [captcha, setCaptcha] = useState<ReCaptcha>()

  /* function setClient(module: any, data: User): unknown {
    return {
      type: 'SET_UC',
      module,
      data,
    }
  } */

  function setName(module: any, name: string): unknown {
    return {
      type: 'SET_NAME',
      module,
      name,
    }
  }
  function SET_INVOICES(module: any, invoices: Invoice[]): unknown {
    return {
      type: 'SET_INVOICES',
      module,
      invoices,
    }
  }
  function SET_REFS(module: any, refs: Refs[]): unknown {
    return {
      type: 'SET_REFS',
      module,
      refs,
    }
  }

  // renew captcha
  useEffect(() => {
    if (googleRecaptcha)
      setTimeout(() => {
        if (googleRecaptcha) {
          // console.log('recaptcha')
          setGoogleRecaptcha(undefined)
          captcha?.execute()
        }
      }, TWO_MINUTES)
  }, [googleRecaptcha])

  function clearMask(s:string):string{
    const value = s.replace(/\D/g, '')

    return value;
  }

  async function handleSubmit(data: any): Promise<void> {
    if (formRef.current) {
      try {
        // console.log(data)
        formRef.current.setErrors({})
        const schema = Yup.object().shape({
          document: Yup.string()
            .min(3, 'No mínimo 3 caracteres')
            .required('Formato inválido'),
        })
        await schema.validate(data, {
          abortEarly: false,
        })
        if (googleRecaptcha) {
          handleConsult(clearMask(data.document))
        } else {
          captcha?.execute()
          handleConsult(clearMask(data.document))
        }
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  /* function setNameClient(module: any, name: string): unknown {
    return {
      type: 'SET_NAME',
      module,
      name,
    }
  } */

  async function handleUC(item: Refs): Promise<void> {
    // const baseEncript = btoa(`${nanoid()}:s${nanoid()}`);

    const baseEncript = sessionStorage.getItem('token')

    try {
      const { data } = await newApi.get<any, AxiosResponse<any>>(
        `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/invoices?ref=${item.id}`,
        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            // "X-Recaptcha-Token": googleRecaptcha,
            // "X-Forwarded-Host": window.location.hostname||process.env.REACT_APP_HOST
          },
        }
      )
      // console.log(data)
      if (data) {
        const y = data[0]
        if (y) {
          const x = y && y.customer &&  y.customer !== null ? y.customer : 'SEM NOME'
          // console.log(x)
          dispatch(setName(modules, x))
        }
      }
      // console.log(data,'userBeta')
      localStorage.setItem('Invoices', JSON.stringify(data))
      dispatch(SET_INVOICES(modules, data))
      if (data[0]) localStorage.setItem('invoice', JSON.stringify(data[0]))
      history.push('/')
    } catch (error) {
      console.log(error)
    }
    // dispatch(setClient(modules, item));
    // dispatch(setNameClient(modules, item.NOME_CLIENTE || '-'));
    /* localStorage.setItem(
      'Invoices',
      JSON.stringify(item),
    ); */
  }

  async function handleConsult(docField: string): Promise<void> {
    setLoading(true)
    const baseEncript = btoa(`${nanoid()}:s${nanoid()}`)
    sessionStorage.setItem('token', baseEncript)
    sessionStorage.setItem(
      'token_created',
      btoa(`${new Date().toISOString()}:s${nanoid()}`)
    )
    if (docField.length < 11) {
      //  console.log(baseEncript)
      try {
        // console.log('12345')
        const { data } = await newApi.get<any, AxiosResponse<any>>(
          `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/ref?ref=${docField}`,
          // `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/invoices?ref=${docField}`,
          {
            headers: {
              Authorization: `Basic ${baseEncript}`,
              'X-Recaptcha-Token': googleRecaptcha,
              'X-Forwarded-Host':
                window.location.hostname || process.env.REACT_APP_HOST,
            },
          }
        )
        // console.log(data)

        if (data) {
          localStorage.setItem('Ref', JSON.stringify(data))
          SET_REFS(modules, data)

          localStorage.setItem('first_time', 'true')
          if (data.id) {
            await handleUC(data)
            setLoading(false)
          } else {
            if (formRef.current)
              formRef.current.setErrors({ document: 'Tente novamente' })
            captcha?.execute()
            setLoadingCaptcha(true)
            setLoading(false)
            localStorage.clear()
            sessionStorage.clear()
            dispatch({
              type: 'RESET',
            })
            history.push('/login')
          }

          // history.push('/uc')

          /* const x = data[0].customer !== null ? data[0].customer : 'SEM NOME'
          // console.log(x)
          dispatch(setName(modules, x))
          localStorage.setItem('Invoices', JSON.stringify(data))
          dispatch(SET_INVOICES(modules, data))

          localStorage.setItem('invoice', JSON.stringify(data[0])) */
        }
        //  console.log(response,'DataNew')
        /*  const { data } = await api.get<any, AxiosResponse<User>>(`/ref/${docField}/current`,{
          headers: {
            Authorization: `Basic ${baseEncript}`,
            "X-Recaptcha-Token": googleRecaptcha,
            "X-Forwarded-Host": window.location.hostname||process.env.REACT_APP_HOST
          }
        }); 
      */
        /*  dispatch(setClient(modules, data));
        dispatch(setNameClient(modules, data.NOME_CLIENTE || "SEM NOME")); */
        /* console.log(data[0].customer)
       const x = data[0].customer !== null ? data[0].customer : "SEM NOME"
       console.log(x)
       dispatch(setName(modules, x)); */

        /*  // 
       localStorage.setItem(
        'infoClient',
        JSON.stringify(data[0]),
      );
      localStorage.setItem(
        'info',
        JSON.stringify(data[0]),
      ); */

        /* localStorage.setItem(
        'infoClient',
        JSON.stringify(data),
      );
      localStorage.setItem(
        'info',
        JSON.stringify(data),
      ); */
        if (!data) {
          if (formRef.current)
            formRef.current.setErrors({ document: 'Tente novamente' })
          captcha?.execute()
          setLoadingCaptcha(true)
          setLoading(false)
          localStorage.clear()
          sessionStorage.clear()
          dispatch({
            type: 'RESET',
          })
          history.push('/login')
        } else {
          setLoading(false)
          localStorage.setItem('first_time', 'true')
          // history.push('/')
        }
      } catch (error) {
        if (formRef.current)
          formRef.current.setErrors({ document: 'Tente novamente' })
        captcha?.execute()
        setLoadingCaptcha(true)
        setLoading(false)
        localStorage.clear()
        sessionStorage.clear()
        dispatch({
          type: 'RESET',
        })
        history.push('/login')
      }
    } else {
      try {
        const { data } = await newApi.get<any, AxiosResponse<any>>(
          `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/refs?doc=${docField}`,
          {
            headers: {
              Authorization: `Basic ${baseEncript}`,
              'X-Recaptcha-Token': googleRecaptcha,
              'X-Forwarded-Host':
                window.location.hostname || process.env.REACT_APP_HOST,
            },
          }
        )
        // console.log(data)

        // console.log(response,'DataNew')

        /* const { data } = await api.get<any, AxiosResponse<any>>(`/${docField}/refs`,{
          headers: {
            Authorization: `Basic ${baseEncript}`,
            "X-Recaptcha-Token": googleRecaptcha,
            "X-Forwarded-Host": window.location.hostname||process.env.REACT_APP_HOST
          }
        }); */
        //  console.log(data)
        if (data && data.length > 0) {
          localStorage.setItem('Refs', JSON.stringify(data))
          SET_REFS(modules, data)
          setLoadingCaptcha(true)
          setLoading(false)
          localStorage.setItem('first_time', 'true')
          history.push('/uc')
          /*   
          if(data.length > 1){
            
            /* localStorage.setItem(
              'infoClient',
              JSON.stringify(data),
            ); 
           /* dispatch(setClient(modules, data[0]));
            dispatch(setNameClient(modules, data[0].NOME_CLIENTE || "SEM NOME")); 
            setLoadingCaptcha(true);
            setLoading(false);
            localStorage.setItem('first_time', 'true');
            history.push("/uc");
          } else {
            dispatch(setClient(modules, data[0]));
            dispatch(setNameClient(modules, data?.NOME_CLIENTE));
            localStorage.setItem(
              'infoClient',
              JSON.stringify(data),
            );
            localStorage.setItem(
              'info',
              JSON.stringify(data[0]),
            );
            setLoading(false);
            localStorage.setItem('first_time', 'true');
            history.push("/");
          }
          */
        } else {
          if (formRef.current)
            formRef.current.setErrors({ document: 'Tente novamente' })
          captcha?.execute()
          localStorage.clear()
          sessionStorage.clear()
          dispatch({
            type: 'RESET',
          })
          setLoadingCaptcha(true)
          setLoading(false)
        }
      } catch (error) {
        if (formRef.current)
          formRef.current.setErrors({ document: 'Tente novamente' })
        captcha?.execute()
        setLoadingCaptcha(true)
        setLoading(false)
        localStorage.clear()
        sessionStorage.clear()
        dispatch({
          type: 'RESET',
        })
        history.push('/login')
      }
    }
  }

  useEffect(() => {
    localStorage.clear()
    sessionStorage.clear()
    dispatch({
      type: 'RESET',
    })
  }, [])

  const verifyCallback = (recaptchaToken: string): void => {
    setLoadingCaptcha(false)
    setGoogleRecaptcha(recaptchaToken)
  }

  useEffect(() => {
    if (!googleRecaptcha) {
      setLoadingCaptcha(true)
    } else {
      setLoadingCaptcha(false)
    }
  }, [googleRecaptcha])

  return (
    <Container>
      <ReCaptcha
        ref={(e) => {
          if (e) setCaptcha(e)
        }}
        sitekey={process.env.REACT_APP_RECAPTCHA as string}
        action="auth_device"
        verifyCallback={verifyCallback}
      />
      <span className="thisRecap">
        <Recaptcha googleRecapt={!loadingCaptcha} />
      </span>
      <Box>
        <BoxLeft>
          <BoxTwoImages>
            <img
              id="imgText"
              src={`${process.env.PUBLIC_URL}/assets/icons/caer_logo.svg`}
              alt="Imagem"
            />
          </BoxTwoImages>
          <BoxTextAndCard>
            <h1 className="box_left_small mb-30">
              Você já economiza água. Agora pode economizar tempo.
            </h1>
            <p className=" box_left_title  mb-15">
              Pague sua conta de água com cartão.
            </p>
            
          </BoxTextAndCard>
         
        </BoxLeft>

        <BoxRight>
          <div className="login_form_container">
            <Form
              ref={formRef}
              className="pb-30 bb-gray"
              onSubmit={handleSubmit}
            >
              
              <p className="login_form_subtitle">
                Para visualizar e pagar faturas, informe o CPF ou a Matrícula
              </p>
              <p className="login_input_label">Informe o CPF ou a Matrícula</p>

              <div>
                <Input
                  className="login_input_container"
                  name="document"
                  type="tel"
                  placeholder="123.456.789-00"
                />

                <FormButton
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loadingCaptcha}
                  style={{
                    filter: !loadingCaptcha ? 'grayscale(0)' : 'grayscale(1)',
                  }}
                  isFocused
                  className="login_consult_button"
                >
                  {loading ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icons/loading.svg`}
                      alt="Loading"
                      style={{ height: 40 }}
                    />
                  ) : (
                    'Consultar'
                  )}
                </FormButton>
              </div>
            </Form>

            <a
              className="login_footer_link"
              href="https://usebill.com.br/"
              target="__blank"
            >
              <div
                style={{
                fontSize: 10,
                marginTop: 20,
                width: 280,
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                <b
                  style={{
                  height: 16,
                  color: '#676767',
                  fontSize: 12,
                  fontWeight: 400,
                }}
                >
                  Arrecadação Multicanal
                  {'   '}
                </b>

                <img
                  style={{ marginLeft: 6, height: 16 }}
                  color="#fff"
                  src={LogoBillGray}
                  alt="Pigz Pay"
                />
              </div>
            </a>

         
          </div>

          
          <div className="box_right_footer_mobile align_self_start">
            <span
              style={{
                height: 16,
                color: '#676767',
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              Desenvolvido por
            </span>
            <img
              style={{ marginLeft: 6 }}
              alt="orange"
              src={`${process.env.PUBLIC_URL}/assets/icons/orange-gray.svg`}
            />
          </div>
        </BoxRight>
      </Box>
    </Container>
  )
}

export default connect((state: any) => ({ modules: state.NOME_CLIENTE }))(UpdatedLogin)
