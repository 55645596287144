/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
// import { nanoid } from 'nanoid';
import { AxiosResponse } from 'axios'

import symbol from '../../assets/icons/symbol.svg'

import { Container, Label, Box, LabelConsumer, Skeleton } from './styles'
import { LoaderIcon } from '../../components/Loader'
import { Invoice, Refs } from '../../utils/models'
import { newApi } from '../../services/api'

const PickUC: React.FC = ({ modules, dispatch }: any) => {
  const [user, setUser] = useState<Array<Refs>>([])
  const [loading, setLoading] = useState(true)
  const temp = localStorage.getItem('Refs')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [])

  useEffect(() => {
    localStorage.removeItem('invoices')
  }, [])

  useEffect(() => {
    if (!temp) {
      setUser([])
      setLoading(false)
    } else {
      const userTemp = JSON.parse(temp)
      setUser(userTemp)
      setLoading(false)
    }
  }, [temp])

  useEffect(() => {
    if (user) {
      if (user.length === 1) {
        handleUC(user[0])
      }
    }
  }, [user])

  const history = useHistory()

  /* function setClient(module: any, data: User): unknown {
    return {
        type: 'SET_UC',
        module,
        data,
    };
  } */
  function setName(module: any, name: string): unknown {
    return {
      type: 'SET_NAME',
      module,
      name,
    }
  }

  function setMatricula(module: any, matricula: string): unknown {
    return {
      type: 'SET_MATRICULA',
      module,
      matricula,
    }
  }

  function setDescription(module: any, endereco: string): unknown {
    return {
      type: 'SET_ENDERECO',
      module,
      endereco,
    }
  }
  /* function setNameClient(module: any, name: string): unknown {
    return {
        type: 'SET_NAME',
        module,
        name,
    };
  } */

  function SET_INVOICES(module: any, invoices: Invoice[]): unknown {
    return {
      type: 'SET_INVOICES',
      module,
      invoices,
    }
  }

  async function handleUC(item: Refs): Promise<void> {
    // const baseEncript = btoa(`${nanoid()}:s${nanoid()}`);

    const baseEncript = sessionStorage.getItem('token')

    history.push('/')

    try {
      const { data } = await newApi.get<any, AxiosResponse<any>>(
        `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/invoices?ref=${item.id}`,
        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            // "X-Recaptcha-Token": googleRecaptcha,
            // "X-Forwarded-Host": window.location.hostname||process.env.REACT_APP_HOST
          },
        }
      )
      console.log(item,modules,data[0])
      const x = data[0] && data[0].customer !== null ? data[0].customer : 'SEM NOME'
      const y = data[0] && data[0].ref ? data[0].ref : item.id;
      const z = data[0] && data[0].extras && data[0].extras.description ? data[0].extras.description : item.description;

      // console.log(y,'y')
      // console.log(x)
      dispatch(setName(modules, x))
      dispatch(setMatricula(modules, y))
      dispatch(setDescription(modules,z))
      // console.log(data,'userBeta')
      localStorage.setItem('Invoices', JSON.stringify(data))
      dispatch(SET_INVOICES(modules, data))
      localStorage.setItem('invoice', JSON.stringify(data[0]))
    } catch (error) {
      console.log(error)
    }
    // dispatch(setClient(modules, item));
    // dispatch(setNameClient(modules, item.NOME_CLIENTE || '-'));
    /* localStorage.setItem(
      'Invoices',
      JSON.stringify(item),
    ); */
  }

  if (loading) {
    return <LoaderIcon />
  }

  function limitcharNumber(c:string,n:number):string{


    let name= '';

    const {length} = c;

    if(length > n)
    name = `${c.slice(0,n)  }...`;

    else{
      name = c
    }


    return name
  }

  return (
    <Container>
      <Label>Selecione a MATRÍCULA que deseja consultar</Label>
      {isLoading
        ? user?.map(() => {
            return (
              <Box disabled>
                <img src={symbol} alt="Caer" style={{ height: 28 }} />
                <LabelConsumer>
                  <span>MATRÍCULA</span>
                  <Skeleton
                    style={{
                      opacity: 0.5,
                      width: 120,
                      height: 25,
                      marginTop: 3,
                    }}
                  />
                </LabelConsumer>
                <LabelConsumer style={{ marginLeft: 20, width: 100 }}>
                  <span>DESCRIÇÃO</span>
                  <Skeleton
                    style={{
                      opacity: 0.5,
                      width: 100,
                      height: 10,
                      marginTop: 3,
                    }}
                  />
                  <Skeleton
                    style={{
                      opacity: 0.5,
                      width: 70,
                      height: 10,
                      marginTop: 5,
                    }}
                  />
                </LabelConsumer>
                <FontAwesomeIcon icon={faArrowRight} size="2x" />
              </Box>
            )
          })
        : user?.map((item) => {
            return (
              <Box
                onClick={() => {
                  handleUC(item)
                  //  dispatch(setClient(modules, item));
                }}
              >
                <img src={symbol} alt="Caer" style={{ height: 28 }} />
                <LabelConsumer>
                  <span>MATRÍCULA</span>
                  <text>{item.id}</text>
                </LabelConsumer>
                <LabelConsumer style={{ marginLeft: 20, width: 100 }}>
                  <span>DESCRIÇÃO</span>
                  <label>{`${limitcharNumber(item.description||'',35)}`}</label>
                </LabelConsumer>
                <FontAwesomeIcon icon={faArrowRight} size="2x" />
              </Box>
            )
          })}
    </Container>
  )
}

export default connect((state: any) => ({ modules: state }))(PickUC)
