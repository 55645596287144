import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  min-height: 70vh;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  font-family: Roboto;
  //padding:10px;
`;

export const Skeleton =  styled.div`
  background-image: linear-gradient(
    -90deg,
    #fafafc 0%,
    #053F75 50%,
    #fafafc 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export const Label = styled.span`
  font-size: 18px;
  margin: 0px 0px 20px 0px;
  color: #2B2B2B;
  font-weight: 500;
  line-height: 28px;
`;

export const Box = styled.button`
  display: flex;
  border: none;
  margin-bottom: 20px;
  padding: 20px;
  color: #fff;
  width: 355px;
  max-width:95vw;
  height: 84px;
  align-items: flex-start;
  background: #053F75;
  border-radius: 8px;
  justify-content: space-between;
  transition: 300ms;
  &:active{
    background: #042e64;
  }
  img{
    margin-top: 5px;
    margin-right: 10px;
  }
`;

export const LabelConsumer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  span{
    font-size: 9px;
    line-height: 12px;
  }
  text{
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
  }
  label{
    text-align: left;
    font-size: 12px;
    font-weight: 500;
  }
`;
