import styled from 'styled-components'

export const Container = styled.div`
  padding: 0;
  width: 100vw;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  #mobile {
    display: none;
  }
  #web {
    display: flex;
  }
  @media only screen and (max-width: 768px) {
    overflow: scroll;
    #mobile {
      display: flex;
    }
    #web {
      display: none;
    }
    .marginIfOn {
      margin-bottom: 60px;
      // background-color:#000;
    }
  }
`

export const Box = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 10px;
  max-height: 90%;
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    width: 100vw;
    max-height: 100%;
    padding: 10px;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
  }
`

export const BoxPrice = styled.div`
  border: none;
  box-shadow: 0px 0px 2px #555;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 874px;
  z-index: 3;
  transition: 0.3s;
  height: 60px;
  border-radius: 8px;
  align-items: center;
  background: #fff;
  position: absolute;
  color: var(--secondaryColor);
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  button {
    background-color: var(--primaryColorDark);
    width: 220px;
    height: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 8px;
    color: #fff;
    font-weight: 500;
    transition: 0.2s;
    &:hover {
      background-color: var(--primaryColorDark);
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    position: absolute;
    display: none;
  }
`

export const BoxInfoDownload = styled.div`
  z-index: 12;
  font-family: 'Roboto';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 283px;
  height: 40px;
  border-radius: 8px;
  margin: auto;
  position: absolute;
  background: var(--secondaryColor);
  span {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }
  @media only screen and (max-width: 768px) {
    width: 95%;
    font-weight: 500;
    font-size: 18px;
    div {
      margin: 10px;
      width: 65vw;
    }
  }
`

export const BoxInfoCopy = styled.div`
  z-index: 12;
  font-family: 'Roboto';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 283px;
  height: 40px;
  border-radius: 8px;
  position: absolute;
  background: var(--secondaryColor);
  span {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }
  @media only screen and (max-width: 768px) {
    width: 95%;
    width: 95%;
    font-weight: 500;
    font-size: 18px;
  }
`

export const BoxInfo = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 283px;
  height: 145px;
  border-radius: 8px;
  transition: 0.3s;
  position: absolute;
  background-color: #555;
  @media only screen and (max-width: 768px) {
    width: 100vw;
    font-weight: 500;
    font-size: 15px;
    div {
      margin: 10px;
      width: 65vw;
    }
  }
`

export const Left = styled.div`
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .leftHeader {
    justify-content: space-between;
  }
  .leftSelectAll {
    display: flex;
  }
  @media only screen and (max-width: 768px) {
    display: none;
    .leftHeader {
      justify-content: center;
    }
    .leftSelectAll {
      display: none;
    }
  }
`

export const BoxInvoicesPending = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media only screen and (max-width: 768px) {
    //  display: none;
    grid-template-columns: repeat(1, 1fr);
  }
  gap: 10px;
  max-height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  > div {
    > div {
      span {
        color: #494949;
        font-size: 16px;
        line-height: 28px;
        font-family: 'Roboto';
        font-weight: 500;
      }
    }
    span {
      color: #494949;
      font-size: 14px;
      line-height: 28px;
      font-family: 'Roboto';
      font-weight: 500;
    }
    button {
      margin-left: 8px;
      border: none;
      width: 35px;
      height: 35px;
      border-radius: 8px;
      background: #edf1f5;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  .btnConfirm {
    margin: 0px;
    width: 100%;
    height: 35px;
    background: var(--primaryColorDark);
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
  .btnProccess {
    margin: 0px;
    width: 100%;
    height: 35px;
    background: #fff;
    border: 1.5px solid var(--secondaryColor);
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    color: var(--secondaryColor);
    cursor: initial;
  }
  .btnOpen {
    border: 1px solid var(--secondaryColor);
    margin: 0px;
    width: 100%;
    height: 35px;
    background: #fff;
    font-family: 'Roboto';
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    color: var(--secondaryColor);
  }
  .nameClient {
    color: #858585;
    font-size: 12px;
    font-family: 'Roboto';
    line-height: 18px;
    font-weight: 300;
  }
  .valueAndDate {
    color: #858585;
    font-size: 15px;
    font-family: 'Roboto';
    line-height: 25px;
    font-weight: 500;
  }
`

export const BoxInvoiceRight = styled.div`
  background-color: #fff;
  position: relative;
  width: 283px;
  z-index: 1;
  padding: 15px 15px 0 15px;
`

export const Right = styled.div`
  max-width: 314px;
  display: none;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    display: flex;
  }
`
export const BoxPriceMobile = styled.button`
  border: none;
  height: 60px;
  left: 0px;
  bottom: 0px;
  transition: 0.5s ease;
  padding: 20px;
  margin: 0px;
  box-shadow: 0px 0px 10px #ccc;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 20;
  transition: 0.3s;
  align-items: center;
  background: var(--primaryColorDark);
  position: fixed;
  color: #fff;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 15px;
  @media only screen and (max-width: 768px) {
    display: flex;
    //position: relative;
  }
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .title {
    font-size: 18px;
    color: #2b2b2b;
    font-weight: 500;
  }
  .allInvoiceMobile {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    .title {
      font-size: 15px;
      color: #2b2b2b;
      font-weight: 500;
    }
    .allInvoiceMobile {
      display: flex;
    }
  }
`

export const BoxInvoices = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    height: 0px;
    background-color: var(--secondaryColor);
    position: absolute !important;
    right: 10px !important;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 20px;
    transform: translate(20px) !important;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
    margin-top: 50px;
    border-bottom-right-radius: 10px;
    position: absolute !important;
    right: 10px !important;
    transform: translate(20px) !important;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--secondaryColor);
    border-radius: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    &::-webkit-scrollbar {
      width: 6px;
      height: 0px;
      background-color: white;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 0px;
    }
    &::-webkit-scrollbar-track {
      background-color: white;
      margin-top: 50px;
      border-bottom-right-radius: 16px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--secondaryColor);
      border-radius: 20px;
    }
  }
`

export const BoxAllInvoices = styled.div`
  margin-top: 15px;
  width: 314px;
  border-radius: 8px;
  max-height: 100%;
  // overflow: auto;
  overflow-y: overlay;
  -webkit-overflow-scrolling: auto; /* pare de rolar imediatamente */

  // -ms-overflow-style: none;
  // scrollbar-width: none;
  background-color: #fff;
  /*  &::-webkit-scrollbar {
    width: 8px;
    height: 0px;
   // background-color: transparent;
    //position: absolute !important;
    //right: 10px !important;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 20px;
  //  transform: translate(20px) !important;
  }
  &::-webkit-scrollbar-track {
    //background-color: transparent;
    margin-top: 50px;
    border-bottom-right-radius: 10px;
    //position: absolute !important;
    //right: 10px !important;
    //transform: translate(20px) !important;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--secondaryColor);
    border-radius: 20px;
    
  }
  */
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: calc(100vh - 30px);
    #ScrollContainer {
      position: sticky;
      position: -webkit-sticky;
      display: block;
      -webkit-overflow-scrolling: auto; /* pare de rolar imediatamente */
    }
    /* &::-webkit-scrollbar {
      width: 8px;
      height: 0px;
    //  background-color: transparent;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 0px;
      
     // background:transparent;

      display:none;
      
      
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      margin-top:0;
      border-bottom-right-radius: 16px;
      
      
      
    }
    
    &::-webkit-scrollbar-thumb {
      background: #676767;
      border-radius: 20px;
      width:4px !important;
     
    }
    */
    //margin-right:8px;

    border-radius: 0;

    ::-webkit-scrollbar {
      background: transparent;

      // display:none;
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      /*  margin-top:0;
      border-bottom-right-radius: 16px;
      background-image: url("https://i.stack.imgur.com/Pwbuz.png");
    background-repeat: repeat-y;
    background-size: contain;
      */
      margin-top: 54px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(103, 103, 103, 0.5);
      border-radius: 20px;
      border: 2.5px solid rgba(0, 0, 0, 0);
      border-right: 5px solid rgba(0, 0, 0, 0);

      background-clip: padding-box;
      max-height: 5px;
    }
  }
`

export const YearTitle = styled.h1`
  height: 50px;
  border-radius: unset;
  width: 314px;
  padding: 10px 16px;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Roboto';
  line-height: 28px;
  margin-bottom: 20px;
  background: var(--secondaryColor);
  color: #fff;
  position: -webkit-sticky;

  position: sticky;
  top: 0;
  z-index: 10;
  @media only screen and (max-width: 768px) {
    width: 100%;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    z-index: 1;
    display: block;
  }
`

export const Invoice = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding: 0px 10px 0px 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
  justify-content: space-between;
  span {
    line-height: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const BtnShowDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 90px;
  height: 40px;
  background-color: #fff;
  border: none;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.55px;
  font-family: 'Roboto';
  font-weight: 500;
  color: var(--secondaryColor);
  border: 0.5px solid var(--secondaryColor);
`

export const BtnCopyDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  background-color: #edf1f5;
  height: 40px;
  border: none;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.55px;
  font-family: 'Roboto';
  font-weight: 500;
  color: var(--secondaryColor);
`

export const BtnHideDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  color: #edf1f5;
  height: 40px;
  border: none;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.55px;
  font-family: 'Roboto';
  font-weight: 500;
  background-color: var(--secondaryColor);
`

export const BtnPayDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  color: #edf1f5;
  height: 40px;
  border: none;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.55px;
  font-family: 'Roboto';
  font-weight: 500;
  background-color: var(--primaryColorDark);
`

export const Skeleton = styled.div`
  background-image: linear-gradient(
    -90deg,
    #fafafc 0%,
    #e4e4e4 50%,
    #fafafc 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`

export const SkeletonGreen = styled.div`
  background-image: linear-gradient(
    -90deg,
    var(--primaryColorDark) 0%,
    #abdb30 50%,
    var(--primaryColorDark) 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`
