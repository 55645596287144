/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { StateInitial } from '../../utils/models';
import iconNoWifi from '../../assets/icons/wifi-slash.svg';

import { Container } from './styles';

interface DataDashDTO {
  modules: boolean;
  dispatch: any;
}

const InternetConnection = ({modules, dispatch }: DataDashDTO):any => {

  const [ isConnected, setIsConnected ] = useState(true);
  const [ delay, setDelay ] = useState(false);

  useEffect(()=>{
    setIsConnected(modules);
  },[modules]);

  useEffect(() => {
    setTimeout(() => {
      setDelay(isConnected);
    }, 200);
  },[isConnected]);

  function setConnectionStatus(module: unknown, connection: boolean): any {
    return {
        type: 'CONNECTION_CHANGED',
        module,
        connection,
    };
  };

  window.addEventListener('online', () => {
    dispatch(setConnectionStatus(modules, true));
  });

  window.addEventListener('offline', () => {
    dispatch(setConnectionStatus(modules, false));
  });

  return (
    <Container style={{
        transition: '0.3s',
        display: !delay ? 'flex' : !modules ? 'flex' : 'none',
        opacity: !modules ? !delay ? 1 : 0 : 0,
        transform: !modules ?  !delay ? 'translate(0px)' : 'translate(0, 200px)' : 'translate(0, 200px)'
      }}
    >
      <img src={iconNoWifi} alt="No Internet" />
      <span>Você está sem internet!</span>
    </Container>
  );
}

export default connect((state: StateInitial) => ({modules: state.CONNECTION}))(InternetConnection);
