import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import redCircleIcon from '../../../../assets/icons/information-red.svg';
import { PaymentDTO } from '../../../../utils/models';

import { Container, Box, Title,Top , Bottom, Button, TextFooterBox, TableInvoices} from './styles';

const Error: React.FC = () => {
  const location = useLocation<unknown>();
  const history = useHistory();
  const [paymentResume, setPaymentResume] = useState<PaymentDTO>();
  const [totalCart, setTotalCart] = useState(0);

  useEffect(() => {
    const tempPaymentDefault = localStorage.getItem('paymentResume');
    if (tempPaymentDefault){
      setPaymentResume(JSON.parse(tempPaymentDefault))
      setTotalCart(JSON.parse(tempPaymentDefault).total)
    }else{
      history.push('/');
    }
  }, [history, location]);

  return (
    <Container>
      <Box>
        <Title>Pagamento não efetuado</Title>
        <Top>
          <div>
            <span>
              Fatura
            </span>
            <span>
              Valor
            </span>
          </div>
          <TableInvoices>
            {paymentResume?.faturas?.map(invoice => {
              return(
                <div key={invoice.id}>
                  <div>
                    <span>{invoice.ref}</span>
                    <span>{`R$ ${invoice.total.toLocaleString('pt-BR', {minimumFractionDigits: 2})}`}</span>
                  </div>
                </div>
              )
            })}
          </TableInvoices>
          <TableInvoices>
            <div>
              <div>
                <span>Total</span>
                <span>{`R$ ${totalCart.toLocaleString('pt-BR', {minimumFractionDigits: 2})}` || '0,00'}</span>
              </div>
            </div>
          </TableInvoices>
        </Top>
        <Bottom>
          <img src={redCircleIcon} alt="Info" />
          <span>Não foi possível processar o pagamento da sua fatura, tente outra forma de pagamento ou entre em contato conosco.</span>
        </Bottom>
        <Button onClick={()=>{
          history.push({
            pathname: "/payment",
            state: {
              data: paymentResume
            }
          });
        }}
        >
          TENTAR PAGAR NOVAMENTE
        </Button>
        <TextFooterBox>
          <h4>
            Fale com a CAER
          </h4>
          <h1>
            Ligue 0800 701 3001
          </h1>
        </TextFooterBox>
      </Box>
    </Container>
);
}
export default Error;
