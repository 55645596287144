import axios from "axios";


export const newApi = axios.create({
   baseURL: process.env.REACT_APP_API_URL
})

export const mundiPagg = axios.create({
  baseURL: process.env.REACT_APP_API_MUNDIPAG_HOST
});
