/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cookie from 'react-cookies'
import { StateInitial, User } from '../../utils/models'
import {
  Container,
  Box,
  Label,
  Content,
  Logout,
  BarLoading,
  Menu,
  ContentTitle,
  ContentUc,
} from './styles'
import logo from '../../assets/icons/logo.svg'
import symbolColored from '../../assets/icons/colored-symbol.svg'
import logout from '../../assets/icons/logout.svg'

interface Data {
  modules: StateInitial
  dispatch: any
}

const Header = ({ modules, dispatch }: Data): any => {
  const history = useHistory()
  const [nameClient, setNameClient] = useState<string | null>()
  const [ucClient, setUcClient] = useState<string | null>()
  const [adress, setAdress] = useState<string | null>()
  const [showDetailsMobile, setShowDetailsMobile] = useState(false)

  useEffect(() => {
    // const userTemp =  localStorage.getItem('info');
    const userTemp = localStorage.getItem('Invoices')
    // console.log(userTemp,'tempoUser')
    const usersTemp = localStorage.getItem('Refs')
    const refTemp = localStorage.getItem('Ref')
    if (userTemp) {
      const userInfo = JSON.parse(userTemp) as any

      //  console.log(userInfo,'userInfo')

      if (userInfo.length > 0) {
        setUcClient(userInfo[0].ref)
        // setAdress(`${userInfo.description}`);
        setNameClient(userInfo[0].customer || '')

        if (usersTemp) {
          const usersInfo = JSON.parse(usersTemp) as any

          setAdress(`${usersInfo[0].description}`)
        } else if (refTemp) {
          const usersInfo = JSON.parse(refTemp) as any

          setAdress(`${usersInfo.description}`)
        }
      } else if (refTemp) {
        const usersInfo = JSON.parse(refTemp) as any
        setAdress(`${usersInfo.description}`)
        setUcClient(usersInfo.id)
      } else {
        const x = modules as any
        if (x.MATRICULA && x.MATRICULA !== '' && usersTemp) {
          setUcClient(x.MATRICULA)
        } else setUcClient(ucClient)
        if (x.ENDERECO && x.ENDERECO !== '' && usersTemp) {
          setAdress(x.ENDERECO)
        } else setAdress(adress)
        setNameClient(nameClient)
      }

      /*  const userInfo = JSON.parse(userTemp) as User;

      setUcClient(userInfo.IDENT_UC);
      setAdress(`${userInfo.LOGR_TIPO_NOME+userInfo.NOME_BAIRRO}`);
      setNameClient(userInfo.NOME_CLIENTE); */
    } else if (!userTemp && usersTemp) {
      const userInfo = JSON.parse(usersTemp)
      setUcClient(userInfo[0].id)
      setAdress(`${userInfo[0].description}`)
      setNameClient('')

      /*  const userInfo = JSON.parse(usersTemp) as Array<User>;
        setUcClient(userInfo[0].IDENT_UC);
        setAdress(`${userInfo[0].LOGR_TIPO_NOME+userInfo[0].NOME_BAIRRO}`);
        setNameClient(userInfo[0].NOME_CLIENTE || userInfo[0].LOGR_TIPO_NOME); */
    }
  }, [modules])

  function reset(): unknown {
    return {
      type: 'RESET',
    }
  }

  function handleLogout(): void {
    localStorage.clear()
    sessionStorage.clear()
    cookie.remove('OrangeLabs')
    dispatch(reset())
    history.push('/login')
  }

  return (
    <Container>
      <Box
        style={{
          maxHeight: showDetailsMobile ? '400px' : '90px',
          transition: '0.3s',
          minHeight: showDetailsMobile ? '400px' : '90px',
        }}
      >
        <div
          id="textTop"
          style={{
            borderBottom: showDetailsMobile ? '0.5px solid #9e9e9e' : 'none',
          }}
        >
          <div className="logoGrid">
            {
              // alterar para generica
            }
            <img src={logo} alt="CAER" className="logoBig" />
            <img
              style={{ height: 39 }}
              src={symbolColored}
              alt="CAER"
              className="logoSmall"
            />
          </div>
          <div id="textViewMobile" style={{ gap: 20 }}>
            {ucClient && (
              <div id="uc" style={{ display: 'flex', flexDirection: 'column' }}>
                <ContentTitle style={{ overflow: 'hidden' }}>
                  {`${nameClient ? `${nameClient.substr(0, 15)}...` : '-'}`}
                </ContentTitle>
                <ContentUc>{`UC ${ucClient || '00000'}`}</ContentUc>
              </div>
            )}
            <Menu
              onClick={() => {
                setShowDetailsMobile(!showDetailsMobile)
              }}
            >
              <FontAwesomeIcon
                style={{
                  transition: '0.3s',
                  transform: showDetailsMobile
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
                }}
                icon={faChevronDown}
                color="var(--secondaryColor)"
                size="1x"
              />
            </Menu>
          </div>
          <div
            id="textViewPc"
            style={{ flexDirection: 'row', gap: 30, alignItems: 'center' }}
          >
            {ucClient && (
              <div id="uc" style={{ display: 'flex', flexDirection: 'column' }}>
                <Label>MATRÍCULA</Label>
                <Content>{ucClient || '00000'}</Content>
              </div>
            )}
            {nameClient && (
              <div
                id="client"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Label>CLIENTE ATUAL</Label>
                <Content>{nameClient}</Content>
              </div>
            )}
            {adress && (
              <div
                id="adress"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Label>DESCRIÇÃO</Label>
                <Content>{adress}</Content>
              </div>
            )}
            <Logout onClick={handleLogout}>
              <img src={logout} alt="Sair" />
            </Logout>
            <Menu
              onClick={() => {
                setShowDetailsMobile(!showDetailsMobile)
              }}
            >
              <FontAwesomeIcon
                style={{
                  transition: '0.3s',
                  transform: showDetailsMobile
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
                }}
                icon={faChevronDown}
                color="var(--secondaryColor)"
                size="1x"
              />
            </Menu>
          </div>
        </div>
        <BarLoading
          style={{
            height: 2,
            visibility: modules.SEARCH_DB ? 'visible' : 'hidden',
          }}
        />
        <div id="textBody">
          {ucClient && (
            <>
              <Label>UNIDADE CONSUMIDORA</Label>
              <Content>{ucClient || '00000'}</Content>
            </>
          )}
          {nameClient && (
            <>
              <Label>CLIENTE ATUAL</Label>
              <Content>{nameClient}</Content>
            </>
          )}
          {adress && (
            <>
              <Label>ENDEREÇO</Label>
              <Content>{adress}</Content>
            </>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Logout onClick={handleLogout}>
              <img src={logout} alt="Sair" style={{ height: 20 }} />
            </Logout>
            <h3 style={{ color: '#494949' }}>Sair</h3>
          </div>
        </div>
      </Box>
    </Container>
  )
}

export default connect((state: StateInitial) => ({ modules: state }))(Header)
